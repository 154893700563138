@import '../utils/variables';
@import '../base/typography';

body {
   background-color: $color-white-100 !important;
}

html,
body,
button,
select {
   @extend %font;
}

a , Link {
   text-decoration: none;
   color: $color-link;
   
   &:hover {
      cursor: pointer;
      color: $color-on-link;
   }
}

.message {
   color: $color-primary;
   font-size: 14px;
   line-height: 120%;
   margin-bottom: 24px;
   max-width: 500px;
   width: auto;
}
