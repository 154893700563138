.toast-wrapper {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 300px;
    z-index: 9999;
}

.toast {
    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0, 0.3);
    overflow: hidden;
    margin-bottom: 20px;
    width: 300px;

    &.close {
        animation: SlideRight 0.4s;
        animation-fill-mode: forwards;
    }

    &.error .bar {
       background-color: $color-red-100;
    }

    &.info .bar {
        background-color: $color-blue-400;
    }

    &.success .bar {
        background-color: $color-green-100;
    }

    &.warning .bar {
        background-color: $color-orange-100;
    }

    & .bar {
         height: 10px;
    }

    & .text-wrapper {
        padding: 15px;
    
        & h4, p {
            margin: 0;
        }
    }
}

.toast:not(.show){
    display: inline-block !important;
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}