.notifications {
   @extend %grid;
   @extend %page;
   @include gridTemplateRows(auto 1fr);
   grid-gap: 24px;
   height: 100%;

   .header {
      @extend %flex;
      @include flexJustifyContent(center);
      @include flexAlignItems(center);

      img {
         width: 80px;
      }

      h2 {
         font-size: 22px;
         line-height: 100%;
         margin: 0;
      }
   }

   .container {
      @extend %column;
      height: 100%;
      padding: 48px 24px;

      .notification-list {
         @extend %grid;
         height: 100%;
         width: 100%;

         .list {
            position: relative;

            ul {
               bottom: 0;
               left: 0;
               overflow: auto;
               padding: 0 16px;
               position: absolute;
               right: 0;
               top: 0;

               .list-item {
                  @extend %grid;
                  @include borderRadius(0 40px 40px 40px);
                  @include boxShadow(
                     0 24px 32px $color-black-200,
                     0 16px 24px $color-black-200,
                     0 4px 8px $color-black-200,
                     0 0 1px $color-black-200
                  );
                  @include gridTemplateColumns(auto 1fr);
                  background-color: $color-grey-50;
                  cursor: pointer;
                  grid-gap: 10px;
                  margin-bottom: 20px;
                  padding: 15px;

                  &.readed {
                     background-color: $color-white-100;

                     .item-text {
                        span {
                           font-weight: normal;
                        }
                     }
                  }

                  .item-text {
                     min-width: 0;

                     span {
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 16px;
                     }
                  }
               }
            }
         }
      }
   }
}

@media screen and (min-width: $breakpoint-large) {
   .notifications {
      .header {
         @include flexJustifyContent(start);
      }
   }
}
