.history-clinic-detail {
   @extend %grid;
   @extend %page;
   @include gridTemplateRows(auto 1fr);
   grid-gap: 10px;

   .btn {
      @extend %button;
      @extend %btnBlueOutlined;
      min-width: initial;
      width: 135px;
      font-size: 13px;
      position: absolute;
      right: 25px;
   }

   .btn-wrapper {

      @extend %flex;
      gap: 10px;
      justify-content: center;
      align-items: center;      
      font-style: normal;
      font-weight: 800;
      font-size: 17px;
      color: #002C54;
      margin-top: 10px;
      margin-bottom: 10px;

      .btn {
         @extend %button;
         @extend %btnBlueOutlined;
         min-width: initial;
         width: auto;
         font-size: 12px;

         &--next {
            position: absolute;
            right: 25px;
         }

         &--back {
            position: absolute;
            left: 25px;
         }

         &--disabled {
            @extend %btnDisabled;
         }

         .icon-back {
            transform: rotate(180deg);
         }

         .MuiButton-label{
            margin-left: 10px;
            margin-right: 10px;
         }

      }

   }


   .header-hc {
      @extend %flex;
      @include flexAlignItems(center);

      img {
         width: 80px;
      }

      h2 {
         color: $color-primary;
         font-size: 20px;
         line-height: 100%;
         margin: 0;
      }
   }

   .column {
      @extend %column;
      @include gridTemplateRows(auto 1fr);
      grid-gap: 10px;

      width: 100%;

      &--heightmax {
         height: 100%;
      }

      &--heightmin {
         height: 150px;
      }

      .header-accodion-consultation{
         margin-top: -14px;
         margin-bottom: -25px;
      }

      .table {
         vertical-align: baseline;

         .table-cell {
            border-bottom: none;
            max-width: inherit;
            .detail {
               .data-wrapper {
                  @extend %flex;
                  @include flexDirection(column);
                  text-align: left;
                  overflow-wrap: anywhere;
                  max-width: 250px;
                  .data {
                     font-weight: 600;
                     font-size: 14px;
                     color: #002C54;
                     .normal {
                        font-weight: normal;
                        margin-left: 5px;
                     }
                     
                     .middle {
                        margin-left: 50px;
                     }
                  }
               }
            }
         }

         .rc {
            margin-left: 15px;
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 14px;
            color: #002C54;

            &.no-margin-left {
               margin-left: 0;
            }
            
            .normal {
               font-family: inherit;
               font-weight: 500;
               color: #002C54;
               margin-left: 5px;
               width: 95%;
               border-color: $color-grey-50;

            }

            .detail {
               min-height: 50px;
            }

         }
      }

      .accordion-history-clinic {
         box-shadow: none;
         @extend %grid;
         color: $color-blue-400;
         
         .MuiAccordionSummary-root{
            
            &:active,
            &:focus,
            &:hover {
               @include borderRadius(0 40px 40px 40px);
               background-color: $color-grey-50;               
            }

         }
         

         .header-accordion {
            font-weight: 800;
         }

         .accordion-detail {
            padding: 0px 16px 0px;
            margin-bottom: -20px;
         }

         .detail-rc{
            padding-left: 15px;
         }

         .data {
            font-weight: 600;
            font-size: 14px;
            color: #002C54;

            .normal {
               font-weight: normal;
               margin-left: 5px;
            }

         }
      }

      .tab-hc {
         width: 20%;
         font-size: 14px;
         margin-top: 20px;
      }

      .panel {
         padding: 10px;

         .container-detail-hc {
            @extend %grid;
            @include borderRadius(0 40px 40px 40px);
            @include boxShadow(0 24px 32px $color-black-200,
               0 16px 24px $color-black-200,
               0 4px 8px $color-black-200,
               0 0 1px $color-black-200);
            background-color: $color-white-100;
            color: $color-blue-400;
            font-weight: bold;
            height: auto;
            margin-bottom: 20px;
            padding-top: 10px;
            padding-left: 30px;
            padding-bottom: 20px;  
            .data {
               font-weight: 600;
               font-size: 14px;
               color: #002C54;

               .normal {
                  font-weight: normal;
                  margin-left: 5px;
               }

            } 
         }

         .collapse{
            margin-left: 15px;            
         }
         .collapse:not(.show) {
            display: inline-block;
         }
         .description{
            margin-left: -6px;
         }
      }

      .list-item-prescription {
         @extend %grid;
         @include borderRadius(0 40px 40px 40px);
         @include boxShadow(0 24px 32px $color-black-200,
            0 16px 24px $color-black-200,
            0 4px 8px $color-black-200,
            0 0 1px $color-black-200);
         @include gridTemplateColumns(1fr auto);
         @include gridTemplateRows(repeat(2, auto));
         background-color: $color-white-100;
         color: $color-blue-400;
         cursor: pointer;
         font-weight: bold;
         height: auto;
         margin-bottom: 20px;

         .presc-data {
            .id-state {
               @extend %flex;
               @include flexDirection(row);

               &__id {
                  margin-left: 5px;
               }

               &__stateNew {
                  background-color: $color-red-100;
                  border-radius: 5px;
                  color: white;
                  margin-left: 10px;
                  width: 90px;
                  text-align: center;
               }

               &__stateSent {
                  background-color: $color-orange-100;
                  border-radius: 5px;
                  color: white;
                  margin-left: 10px;
                  width: 150px;
                  text-align: center;
               }

               &__stateOverdue {
                  background-color: $color-grey-200;
                  border-radius: 5px;
                  color: white;
                  margin-left: 10px;
                  width: 90px;
                  text-align: center;
               }
            }

            .detail {
               @extend %flex;
               @include flexDirection(column);
               font-size: 12px;
               font-weight: normal;
               margin-left: 10px;
               margin-top: 10px;

            }
         }
         
         .description {
            @include gridColumn(1, 3);

            .collapse {
               height: 80px;
               width: 100%;

               .cant-descrip {
                  @extend %flex;
                  @include flexDirection(row);

                  .cant {
                     color: $color-grey-200;
                     font-weight: lighter;
                     margin-right: 10px;
                  }

                  .description {
                     color: $color-grey-200;
                     font-weight: lighter;
                  }
               }

               .medicine {
                  .medication {
                     @extend %flex;
                     @include flexDirection(row);

                     .medicine-number {
                        color: $color-black-100;
                        font: sans-serif;
                        font-weight: normal;
                        margin-left: 5px;
                        margin-right: 25px;
                     }

                     .medicine-edit {
                        color: $color-black-100;
                        font: sans-serif;
                        font-weight: normal;
                        margin-top: -5px;
                        margin-left: 10px;
                        margin-right: 25px;
                     }

                     .medicine-name {
                        color: $color-black-100;
                        font: sans-serif;
                        font-weight: normal;
                     }
                  }
               }
            }
         }
      }
   }
}
