.home {
   @include filter(blur(2.5px));
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   height: 100%;
}

.home-container {
   @extend %flexCenter;
   @include borderRadius(0 20px 20px 20px);
   @include flexDirection(column);
   background-color: $color-primary;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   padding: 24px;
   position: absolute;
   width: 80%;
   z-index: 1000;

   .header {
      @extend %flex;
      @include flexAlignItems(center);
      @include flexDirection(column);
      margin-bottom: 20px;
      width: 100%;

      img {
         width: 100px;
         margin: 0 0 10px 0;
      }

      h2 {
         color: $color-white-100;
         font-size: 28px;
         line-height: 100%;
         margin: 0;
      }
   }

   .button-wrapper {
      @extend %grid;
      @include gridTemplateColumns(1fr);
      grid-gap: 10px;
      width: 100%;

      .button {
         color: $color-white-100;
         font-size: 18px;
         height: 42px;

         &--login {
            background-color: $color-secondary;
         }

         &--sign-up {
            border-color: $color-white-100;
         }
      }
   }
}

@media screen and (min-width: $breakpoint-medium) {
   .home-container {
      padding: 32px;
      width: 25%;

      .header {
         @include flexJustifyContent(space-between);
         @include flexDirection(row);

         img {
            margin: 0 10px 10px 0;
         }
      }
   }
}
