.my-orders {
   @extend %grid;
   @extend %page;
   @include gridTemplateRows(auto 1fr);
   grid-gap: 24px;
   height: 100%;

   .header {
      @extend %flex;
      @include flexAlignItems(center);
      @include flexDirection(column);

      .image-title {
         @extend %flex;
         @include flexAlignItems(center);

         img {
            padding: 10px;
            width: 70px;
         }

         h2 {
            font-size: 22px;
            line-height: 100%;
            margin: 0;
         }
      }

      .btn-group {
         .btn {
            &.active {
               background-color: $color-blue-mediline;
               color: white;
            }
         }
      }
   }

   .container {
      @extend %column;
      height: 100%;
      padding: 48px 24px;

      .order-list {
         @extend %grid;
         @include gridTemplateRows(repeat(2, auto));
         height: 100%;
         width: 100%;

         .text {
            font-size: 0.9em;
            text-align: center;
         }

         .list {
            position: relative;

            ul {
               bottom: 0;
               left: 0;
               overflow: auto;
               padding: 0 16px;
               position: relative;
               right: 0;
               top: 0;

               .list-item-order {
                  @extend %grid;
                  @include borderRadius(0 40px 40px 40px);
                  @include boxShadow(
                     0 24px 32px $color-black-200,
                     0 16px 24px $color-black-200,
                     0 4px 8px $color-black-200,
                     0 0 1px $color-black-200
                  );
                  @include gridTemplateColumns(1fr auto);
                  @include gridTemplateRows(repeat(2, auto));
                  background-color: $color-white-100;
                  color: $color-blue-400;
                  cursor: pointer;
                  font-weight: bold;
                  height: auto;
                  margin-bottom: 20px;
                  .order-data {
                     .order-number {
                        @extend %flex;
                        @include flexDirection(row);
                        margin-bottom: 20px;
                        &__span {
                           width: 80px;
                        }
                        &__stateQuoted {
                           width: 100%;
                           @extend %flex;
                           @include flexDirection(row);

                           .div-state {
                              background-color: $color-yellow-100;
                              border-radius: 5px;
                              color: white;
                              margin-left: 20px;
                              width: 90px;
                              text-align: center;
                              height: 20px;
                           }
                           .div-button {
                              width: 100%;
                              text-align: right;

                              .prepaid-button {
                                 background-color: $color-yellow-200;
                                 color: $color-white-100;
                                 margin-left: 15px;
                              }
                           }
                        }
                        &__stateCreated {
                           background-color: $color-yellow-300;
                           border-radius: 5px;
                           color: white;
                           margin-left: 20px;
                           width: 90px;
                           text-align: center;
                        }
                        &__statePrepaid {
                           background-color: $color-blue-100;
                           border-radius: 5px;
                           color: white;
                           margin-left: 20px;
                           width: 90px;
                           text-align: center;
                        }
                        &__statePaid {
                           background-color: $color-green-400;
                           border-radius: 5px;
                           color: white;
                           margin-left: 20px;
                           width: 90px;
                           text-align: center;
                        }
                        &__stateDelivered {
                           background-color: $color-purple-100;
                           border-radius: 5px;
                           color: white;
                           margin-left: 20px;
                           width: 90px;
                           text-align: center;
                        }
                        &__stateSent {
                           width: 100%;
                           @extend %flex;
                           @include flexDirection(row);
                           .div-state-sent {
                              background-color: $color-blue-150;
                              border-radius: 5px;
                              color: white;
                              margin-left: 20px;
                              width: 90px;
                              text-align: center;
                              height: 20px;
                           }
                           .div-button-sent {
                              width: 100%;
                              text-align: right;
                           }
                        }
                        &__stateRejected {
                           background-color: $color-red-100;
                           border-radius: 5px;
                           color: white;
                           margin-left: 20px;
                           width: 90px;
                           text-align: center;
                        }
                     }
                     .pharmacy-expiration {
                        @extend %flex;
                        @include flexDirection(column);
                        &__direction {
                           font-weight: normal;
                        }
                        .created {
                          color: $color-yellow-300;
                        }
                        .quoted {
                           color: $color-yellow-100;
                        }
                        .pre_paid {
                           color: $color-blue-100;
                        }
                        .paid {
                           color: $color-green-400;
                        }
                        .sent {
                           color: $color-blue-150;
                        }
                        .delivered {
                           color: $color-purple-100;
                        }
                        .rejected {
                           color: $color-red-100;
                        }
                        
                     }
                     .total {
                        @extend %flex;
                        @include flexDirection(column);
                        justify-content: right;
                        width: 100%;
                        .description {
                           width: 100%;
                           @include gridColumn(1, 3);
                           .MuiButtonBase-rootMuiListItem-rootMuiListItem-guttersMuiListItem-button {
                              width: 100%;
                              border-bottom: 2px solid $color-grey-50;
                           }
                           .MuiCollapse-wrapperInner {
                              .collapse {
                                 .list-item-prescription {
                                    @extend %grid;

                                    @include gridTemplateColumns(1fr auto);
                                    @include gridTemplateRows(repeat(2, auto));
                                    background-color: $color-white-100;
                                    color: $color-blue-400;
                                    cursor: pointer;
                                    font-weight: bold;
                                    height: auto;
                                    margin-bottom: 50px;
                                    margin-top: 20px;

                                    .presc-data {
                                       border-bottom: 2px solid $color-grey-50;

                                       .id-state {
                                          @extend %flex;
                                          @include flexDirection(row);

                                          &__id {
                                             margin-left: 5px;
                                          }
                                       }

                                       .detail {
                                          @extend %flex;
                                          @include flexDirection(column);
                                          font-size: small;
                                          font-weight: normal;
                                          margin-left: 10px;
                                          margin-top: 10px;
                                          margin-bottom: 20px;

                                          .professional-data {
                                             @extend %flex;
                                             @include flexDirection(row);

                                             &__professional {
                                                margin-right: 5px;
                                             }

                                             .name {
                                                @extend %bold;
                                             }
                                          }

                                          .enrollment {
                                             @extend %flex;

                                             &__label {
                                                margin-right: 5px;
                                             }

                                             &__value {
                                                @extend %bold;
                                             }
                                          }
                                       }
                                    }

                                    .checkbox {
                                       @include gridAlignSelf(flex-start);
                                    }

                                    .description-prescription {
                                       @include gridColumn(1, 3);

                                       .collapse-presctiption {
                                          height: 80px;
                                          width: 100%;
                                          margin-bottom: 45px;
                                          .cant-descrip {
                                             @extend %flex;
                                             @include flexDirection(row);
                                             margin-top: 15px;
                                             margin-bottom: 20px;
                                             color: $color-grey-200;
                                             font-weight: lighter;

                                             .cant {
                                                margin-right: 7px;
                                             }
                                             .price {
                                                text-align: right;
                                                width: 120px;
                                             }
                                             .min {
                                                text-align: right;
                                                width: 80px;
                                             }
                                             .description {
                                                width: 55%;
                                             }
                                          }
                                          .medicine {
                                             .medication {
                                                @extend %flex;
                                                @include flexDirection(row);
                                                margin-bottom: 10px;
                                                font-weight: normal;

                                                .medicine-number {
                                                   color: $color-black-100;
                                                   font: sans-serif;
                                                   margin-left: 5px;
                                                   margin-right: 25px;
                                                   width: 1%;
                                                }

                                                .medicine-name {
                                                   color: $color-black-100;
                                                   font: sans-serif;
                                                   width: 55%;
                                                }
                                                .medicine-price {
                                                   text-align: right;
                                                   width: 120px;
                                                }
                                                .medicine-min {
                                                   text-align: right;
                                                   width: 80px;
                                                }
                                             }
                                          }
                                       }
                                    }
                                 }
                              }
                           }
                        }
                        .extras {
                           @include gridColumn(1, 3);
                           .title {
                              height: 40px;
                              width: 100%;
                              justify-content: left;
                              font-size: 0.875rem;
                              margin-bottom: 20px;
                              border-bottom: 2px solid $color-grey-50;
                           }
                           .collapse-extras {
                              height: 80px;
                              width: 100%;
                              margin-bottom: 45px;
                              .cant-descrip {
                                 @extend %flex;
                                 @include flexDirection(row);
                                 margin-top: 15px;
                                 margin-bottom: 20px;
                                 color: $color-grey-200;
                                 font-weight: lighter;

                                 .cant {
                                    margin-right: 7px;
                                 }
                                 .price {
                                    text-align: right;
                                    width: 120px;
                                 }
                                 .min {
                                    text-align: right;
                                    width: 80px;
                                 }
                                 .description {
                                    width: 55%;
                                 }
                              }
                              .extras {
                                 .extra-data {
                                    @extend %flex;
                                    @include flexDirection(row);
                                    margin-bottom: 10px;
                                    font-weight: normal;
                                    .extra-number {
                                       color: $color-black-100;
                                       margin-left: 5px;
                                       margin-right: 25px;
                                       width: 1%;
                                    }
                                    .extra-name {
                                       color: $color-black-100;
                                       font: sans-serif;
                                       width: 55%;
                                    }
                                    .extra-price {
                                       text-align: right;
                                       width: 120px;
                                    }
                                    .extra-min {
                                       text-align: right;
                                       width: 80px;
                                    }
                                    .extra-discounts {
                                       text-align: right;
                                       width: 200px;
                                    }
                                 }
                              }
                           }
                        }

                        .order-discounts {
                           @include gridColumn(1, 3);
                           .mt-25 {
                              margin-top: 25px !important;
                           }
                           .order-data {
                              @extend %flex;
                              @include flexDirection(row);
                              margin-top: 15px;
                              margin-bottom: 15px;
                              color: $color-grey-200;
                              .order-number {
                                 margin-left: 5px;
                                 margin-right: 25px;
                                 width: 1%;
                              }
                              .order-name {
                                 font: sans-serif;
                                 width: 55%;
                              }
                              .order-price {
                                 text-align: right;
                                 width: 120px;
                              }
                              .order-min {
                                 text-align: right;
                                 width: 80px;
                              }
                              .order-discounts {
                                 text-align: right;
                                 width: 200px;
                              }
                           }
                        }
                        .spantotal {
                           @extend %flex;
                           justify-content: right;
                           margin-top: 15px;
                        }
                     }
                  }
               }
            }
         }

         .empty {
            @extend %flex;
            @include flexDirection(column);

            .message {
               color: $color-primary;
               font-size: 14px;
               line-height: 120%;
               margin-bottom: 24px;
               max-width: 500px;
               width: auto;
            }
         }
      }
   }
}

@media screen and (min-width: $breakpoint-large) {
   .my-orders {
      .header {
         @include flexDirection(row);
         @include flexJustifyContent(space-between);

         .image-title {
            @include flexDirection(row);
         }
      }

      .container {
         .order-list {
            .text {
               font-size: 0.9em;
               padding: 0 16px;
               text-align: left;
            }

            .btn-wrapper {
               @include gridJustifySelf(left);
            }
         }
      }
   }
}
