/* Phone container */
.phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    animation: call 2s ease infinite;
    font-size: 35px;
    font-weight: bold;
    position: relative;
  }
  
  /* Phone icon */
  .phone-icon {
    color: #49c64b;
    transition: transform 0.5s ease-in-out;
    height: 50px;
    width: 50px;
    animation: callAnimation 2s infinite;
  }

  @keyframes call {
    15% {
        box-shadow: 0 0 0 4px rgba(73,198,75, 0.5);
    }
    25% {
        box-shadow: 0 0 0 8px rgba(73,198,75, 0.5),
                    0 0 0 16px rgba(73,198,75, 0.3);
    }
    30% {
        box-shadow: 0 0 0 12px rgba(73,198,75, 0.5),
                    0 0 0 24px rgba(73,198,75, 0.3);
    }
    }
  @keyframes callAnimation {
    0% {
        height: 50px;
        width: 50px;
    }
    50% {
        height: 60px;
        width: 60px;
    }
    100% {
        height: 50px;
        width: 50px;
    }
  }
  
  /* Button styles */
  button {
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  