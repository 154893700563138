@font-face {
   font-family: 'Raleway';
   src: url('../../assets/font/raleway/raleway-v18-latin-regular.eot');
   src: url('../../assets/font/raleway/raleway-v18-latin-regular.eot?#iefix')
      format('embedded-opentype');
   src: url('../../assets/font/raleway/raleway-v18-latin-regular.woff')
      format('woff');
   src: url('../../assets/font/raleway/raleway-v18-latin-regular.ttf')
      format('truetype');
   src: url('../../assets/font/raleway/raleway-v18-latin-regular.svg')
      format('svg');
}

%font {
   font-family: 'Raleway', sans-serif !important;
}
