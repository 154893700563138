.history-clinic {
   @extend %grid;
   @extend %page;
   @include gridTemplateRows(auto 1fr);
   grid-gap: 10px;
   height: 100%;

   .header {
      @extend %flex;
      @include flexAlignItems(center);

      img {
         width: 80px;
      }

      h2 {
         color: $color-primary;
         font-size: 22px;
         line-height: 100%;
         margin: 0;
      }
   }

   .column {
      @extend %column;
      @include gridTemplateRows(auto 1fr);
      grid-gap: 10px;
      height: 100%;
      width: 100%;

      .search-box {
         @extend %column;
         height: 100%;
         width: 100%;

         .detail-wrapper {
            @extend %grid;
            @include gridTemplateColumns(1fr);
            grid-gap: 24px;
            height: 100%;
         }
      }

      .searchBox {
         padding: auto;
      }

      .result {
         @extend %column;
         @include gridTemplateRows(auto 1fr);
         grid-gap: 10px;
         height: 100%;
         width: 100%;
      }

      .btn-search {
         @include borderRadius(25px);
         @include gridAlignSelf(flex-end);
         @include gridJustifySelf(flex-end);
         background-color: white;
         border: 1px solid $color-primary;
         color: $color-primary;
         font-size: 1em;
         font-weight: bold;
         height: 39px;
         line-height: 21px;
         max-width: 150px;
         text-transform: initial;
         width: 100%;

         &:active,
         &:focus,
         &:hover {
            background-color: white;
         }
      }
   }
}

@media screen and (min-width: 1025px) {
   .history-clinic {
      .column {
         .search-box {
            .detail-wrapper {
               @include gridTemplateColumns(repeat(3, 1fr));
            }
         }
      }
   }
}
