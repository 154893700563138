.prescription {
   @extend %grid;
   @extend %page;
   @include gridTemplateRows(auto 1fr);
   grid-gap: 24px;
   height: 100%;

   .header {
      @extend %flex;
      @include flexAlignItems(center);
      @include flexDirection(column);

      .image-title {
         @extend %flex;
         @include flexAlignItems(center);

         img {
            width: 80px;
         }

         h2 {
            font-size: 22px;
            line-height: 100%;
            margin: 0;
         }
      }

      .btn-group {
         .btn {
            &.active {
               background-color: $color-blue-mediline;
               color: white;
            }
         }
      }
   }

   .container {
      @extend %column;
      height: 100%;
      padding: 48px 24px;

      .prescription-list {
         @extend %grid;
         height: 100%;
         width: 100%;

         .list {
            position: relative;

            ul {
               bottom: 0;
               left: 0;
               overflow: auto;
               padding: 0 16px;
               position: relative;
               right: 0;
               top: 0;

               .list-item-prescription {
                  @extend %grid;
                  @include borderRadius(0 40px 40px 40px);
                  @include boxShadow(
                     0 24px 32px $color-black-200,
                     0 16px 24px $color-black-200,
                     0 4px 8px $color-black-200,
                     0 0 1px $color-black-200
                  );
                  @include gridTemplateColumns(1fr auto);
                  @include gridTemplateRows(repeat(2, auto));
                  background-color: $color-white-100;
                  color: $color-blue-400;
                  //cursor: pointer;
                  font-weight: bold;
                  height: auto;
                  margin-bottom: 20px;

                  .presc-data {
                     .id-state {
                        @extend %flex;
                        @include flexDirection(row);

                        &__id {
                           margin-left: 5px;
                        }
                        &__stateNew {
                           background-color: $color-red-100;
                           border-radius: 5px;
                           color: white;
                           margin-left: 10px;
                           width: 90px;
                           text-align: center;
                        }
                        &__stateSent {
                           background-color: $color-yellow-200;
                           border-radius: 5px;
                           color: white;
                           margin-left: 10px;
                           width: 150px;
                           text-align: center;
                        }
                        &__stateOverdue {
                           background-color: $color-grey-200;
                           border-radius: 5px;
                           color: white;
                           margin-left: 10px;
                           width: 90px;
                           text-align: center;
                        }
                     }

                     .detail {
                        @extend %flex;
                        @include flexDirection(column);
                        font-size: small;
                        font-weight: normal;
                        margin-left: 10px;
                        margin-top: 10px;

                        .professional-data {
                           @extend %flex;
                           @include flexDirection(row);

                           &__professional {
                              margin-right: 5px;
                           }

                           .name {
                              @extend %bold;
                           }
                        }

                        .enrollment {
                           @extend %flex;

                           &__label {
                              margin-right: 5px;
                           }

                           &__value {
                              @extend %bold;
                           }
                        }
                     }
                  }

                  .checkbox {
                     @include gridAlignSelf(flex-start);
                  }

                  .description {
                     @include gridColumn(1, 3);

                     .collapse {
                        height: 80px;
                        width: 100%;

                        .cant-descrip {
                           @extend %flex;
                           @include flexDirection(row);

                           .cant {
                              color: $color-grey-200;
                              font-weight: lighter;
                              margin-right: 10px;
                           }

                           .description {
                              color: $color-grey-200;
                              font-weight: lighter;
                           }
                        }
                        .medicine {
                           .medication {
                              @extend %flex;
                              @include flexDirection(row);

                              .medicine-number {
                                 color: $color-black-100;
                                 font: sans-serif;
                                 font-weight: normal;
                                 margin-left: 5px;
                                 margin-right: 25px;
                              }

                              .medicine-edit {
                                 color: $color-black-100;
                                 font: sans-serif;
                                 font-weight: normal;
                                 margin-top: -5px;
                                 margin-left: 10px;
                                 margin-right: 25px;
                              }

                              .medicine-name {
                                 color: $color-black-100;
                                 font: sans-serif;
                                 font-weight: normal;
                              }
                           }
                        }
                     }

                     .collapse:not(.show) {
                        display: inline-block;
                    }
                  }
               }
            }
         }
      }
      .button-sent {
         width: 100%;
         text-align: right;
      }

      .empty {
         @extend %flex;
         @include flexDirection(column);

         .message {
            color: $color-primary;
            font-size: 14px;
            line-height: 120%;
            margin-bottom: 24px;
            max-width: 500px;
            width: auto;
         }
      }
   }
}

@media screen and (min-width: $breakpoint-medium) {
   .prescription {
      .header {
         @include flexDirection(row);
         @include flexJustifyContent(space-between);

         .image-title {
            @include flexDirection(row);
         }
      }
      .container {
         .prescription-list {
            .list {
               .ul {
                  .list-item-prescription {
                     .presc-data {
                        .id-state {
                           @include gridTemplateColumns(1fr 2fr);
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
