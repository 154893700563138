.full-registration {
   @extend %grid;
   @extend %page;
   @include gridTemplateRows(auto 1fr);
   grid-gap: 10px;
   height: 100%;

   .header {
      @extend %flex;
      @include flexAlignItems(center);

      img {
         width: 80px;
      }

      h2 {
         color: $color-primary;
         font-size: 22px;
         line-height: 100%;
         margin: 0;
      }
   }

   .column {
      @extend %column;
      @include gridTemplateRows(auto 1fr);
      grid-gap: 10px;
      height: 100%;
      width: 100%;

      .step {
         & .MuiStepIcon-active {
            color: $color-secondary;
         }

         & .MuiStepIcon-completed {
            color: $color-primary;
         }
      }

      .container {
         form {
            @extend %grid;
            @extend %flexCenter;
            @include gridTemplateRows(auto 1fr auto);
            @include gridTemplateColumns(1fr);
            grid-gap: 36px;
            height: 100%;

            .header {
               @include flexJustifyContent(center);

               &--detail {
                  h2 {
                     color: $color-black-100;
                     font-size: 18px;
                  }
               }
            }

            .field {
               width: 100%;
               margin: 10px 0;
               min-height: 90px;

               &--radio {
                  min-height: 100px;
               }
            }

            .form {
               @extend %grid;

               .field-wrapper {
                  @extend %flex;
                  @include flexDirection(row);
                  grid-gap: 24px;
               }
            }

            .detail-wrapper-two-colum {
               @extend %grid;
               grid-gap: 24px;
            }

            .detail-wrapper-one-colum {
               @extend %grid;
               grid-gap: 24px;
            }

            .select-wrapper {
               @extend %flex;
               @include flexDirection(column);
               @include gridJustifySelf(center);
               height: 110px;
               max-width: 450px;
               padding: 10px 0;
               width: 100%;

               .MuiFormLabel-root {
                  &.MuiInputLabel {
                     &-formControl {
                        top: 5px;
                        left: 10px;
                     }

                     &-shrink {
                        transform: translate(0, -10px) scale(1);
                     }
                  }
               }

               .helper-text {
                  margin: 0;
                  padding: 5px 10px;

                  &.error {
                     color: $color-red-100;
                  }
               }
            }

            .detail-wrapper {
               @extend %grid;
               grid-gap: 24px;

               .detail {
                  @extend %grid;
                  @include gridTemplateRows(auto 1fr);
                  grid-gap: 24px;

                  .data-wrapper {
                     @extend %flex;
                     @include flexDirection(column);

                     .data {
                        margin: 5px 0;
                        font-size: 16px;

                        .bold {
                           @extend %bold;
                           margin-left: 5px;
                        }

                        .options {
                           @extend %bold;
                           @extend %flex;
                           @include flexDirection(column);
                        }
                     }

                     &.profile-photo {
                        @include gridJustifySelf(center);

                        .photo {
                           @include borderRadius(50%);
                           height: 250px;
                           width: 250px;
                        }
                     }
                  }
               }
            }
         }
      }
      .btn-wrapper {
         @extend %grid;
         @include gridTemplateColumns(repeat(2, 1fr));
         grid-gap: 24px;

         .btn {
            @include borderRadius(25px);
            height: auto;
            font-size: 16px;
            padding: 8px 16px;
            width: 100%;

            &--back {
               @include gridColumn(1, 2);
               color: $color-primary;
               border-color: $color-primary;
               margin-right: 15px;
            }

            &--home {
               color: $color-primary;
               border-color: $color-primary;
               width: 144px;
            }

            &--next {
               @include gridColumn(2, 3);
               background-color: $color-primary;
               color: $color-white-100;
            }

            &--save {
               @include gridColumn(2, 3);
               background-color: $color-secondary;
               color: $color-white-100;
            }

            &--disabled {
               background-color: $color-grey-100;
               border-color: $color-grey-100;
               color: $color-white-100;
            }
         }
      }
   }
}

@media screen and (min-width: $breakpoint-large) {
   .full-registration {
      .column {
         grid-gap: 16px;
         padding: 48px;

         .container {
            form {

               .header--detail {
                  @include flexJustifyContent(start !important);
               }
               .form {
                  @include gridTemplateColumns(repeat(2, 1fr));
                  grid-gap: 48px;
                  min-height: 300px;

                  .field-wrapper {
                     grid-gap: 48px;
                  }

                  &--professional {
                     @include gridTemplateColumns(repeat(3, 1fr));
                  }
               }

               .autocomplete-wrapper {
                  @include gridJustifySelf(center);
                  width: 60%;
               }

               .detail-wrapper-two-colum {
                  @include gridTemplateColumns(repeat(2, 1fr));
               }

               .detail-wrapper-one-colum {
                  @include gridTemplateColumns(repeat(1, 1fr));
               }

               .detail-wrapper {
                  @include gridTemplateColumns(repeat(3, 1fr));

                  &--professional {
                     @include gridTemplateColumns(repeat(4, 1fr));
                  }

                  .detail {
                     .data-wrapper {
                        &.profile-photo {
                           @include gridJustifySelf(start);
                        }
                     }
                  }
               }
            }
         }

         .btn-wrapper {
            display: block;
            @include gridAlignSelf(end);
            @include gridJustifySelf(end);

            .btn {
               height: 42px;
               width: 144px;
            }
         }
      }
   }
}
