.radio-group-field {
   .radio-group {
      @extend %flex;
      @include flexDirection(row !important);
   }
}

.file-upload-field {
   @extend %flexCenter;
   @include gridColumn(1, 3);
   @include flexDirection(column);

   .show-image {
      @extend %flex;
      @include flexJustifyContent(center);
      height: 250px;
      width: 250px;

      .image {
         @extend %imgResponsive;
         @include borderRadius(50%);
      }

      .empty {
         @include flexAlignSelf(center);
      }
   }

   &__form {
      @include flexAlignItems(center);
      height: 64px;
      margin-top: 15px !important;

      .btn {
         @include borderRadius(25px !important);
         background-color: $color-grey-100 !important;
         color: $color-black-100 !important;
         font-size: 16px !important;
         height: 42px;
         padding: 8px 16px !important;
         width: 300px;
      }
   }
}

.field-label,
.field-helper-text {
   font-size: 14px !important;
   margin-top: 10px !important;

   &.error {
      color: $color-red-100;
   }
}
