%bold {
   font-weight: bold;
}

%containerBreakpoint {
   max-width: $breakpoint-view;
   margin: 0 auto;
   width: 100%;
}

%column {
   @extend %grid;
   @include borderRadius(5px);
   @include boxShadow(
      0 24px 32px $color-black-200,
      0 16px 24px $color-black-200,
      0 4px 8px $color-black-200,
      0 0 1px $color-black-200
   );
   grid-gap: 10px;
   padding: 16px 24px;
}

%flex {
   display: flex;
   display: -webkit-box;
   display: -moz-box;
   display: -ms-flexbox;
   display: -webkit-flex;
}

%flexCenter {
   @extend %flex;
   @include flexJustifyContent(center);
   @include flexAlignItems(center);
}

%grid {
   display: -moz-grid;
   display: -ms-grid;
   display: grid;
}

%imgResponsive {
   width: 100%;
   height: auto;
   display: block;
}

%inlineFlex {
   display: -webkit-inline-flex;
   display: -ms-inline-flexbox;
   display: inline-flex;
}

%noFlick {
   -webkit-backface-visibility: hidden;
   -webkit-transform: translate3d(0, 0, 0);
}

%truncateText {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

%page {
   height: 100%;
   padding: 16px 24px;
}

%container {
   @extend %grid;
   @include borderRadius(5px);
   @include boxShadow(
      0 24px 32px $color-black-200,
      0 16px 24px $color-black-200,
      0 4px 8px $color-black-200,
      0 0 1px $color-black-200
   );
   @include gridTemplateRows(auto 1fr);
   grid-gap: 16px;
   height: 100%;
   padding: 16px 24px;
   width: 100%;
}
