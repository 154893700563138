.title_container{
    margin: 10px 0;
    text-align: center;
    border-bottom: 1px solid grey;
}

.table{

    .icon{
        fill: #002c54;
        height: 22px;
        margin: 0 10px;
    }
}

.button-container{
    display: flex;
    justify-content: flex-end;

    .button{
        margin: 1rem;
        border: 0;
        border-radius: 20px;
        width: 7rem;
        height: 2rem;
        color: white;
    }

    .add-button{
        background-color: #002C54;
        width: 9rem;
    }

    .see-plan-button{
        background-color: #f02727;
    }

    .save-button{
        background-color: #002C54; 
    }
}