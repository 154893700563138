.role-icon {
   @extend %flexCenter;
   @include flexDirection(column);

   img {
      height: 100px;
      padding: 10px;
      width: 100px;
   }

   &.active {
      .text {
         color: $color-secondary;
      }
   }

   & .text {
      color: $color-grey-100;
      margin-top: 10px;
      font-weight: bold;
   }
}
