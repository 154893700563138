.password-update {
    @extend %grid;
    @extend %page;
    @extend %column;
    @include gridTemplateRows(auto 1fr);
    grid-gap: 24px;
    height: 100%;
    width: 100%;

    .header {
        @extend %flex;
        @include flexAlignItems(center);
        @include flexDirection(column);

        .image-title {
            @extend %flex;
            @include flexAlignItems(center);

            svg {
                font-size: 40pt;
                color: $color-red-mediline;
                font-weight: 200;
                width: 70px;
            }

            h2 {
                font-size: 22px;
                line-height: 100%;
                margin: 0;
            }
        }
    }
    .container {
        @extend %flex;
        @include flexAlignItems(center);
        @include flexDirection(column);
        padding: 48px 24px;
        width: 100%;
        form {
            width: 700px;
            .btn-container {
                @extend %flex;
                @include flexAlignItems(center);
                @include flexDirection(column);
            }
            .submit {
                background-color: $color-red-mediline;
                border-radius: 20px;
                text-transform: 'capitalize';
                color: $color-white-100;
                margin-top: 20px;
                max-width: 300px;
                &:hover {
                  background-color: $color-red-200;
                }
                &:disabled {
                    background-color: $color-grey-100;
                    border-color: $color-grey-100;
                 }
            }
        }
    }
}


@media screen and (max-width: $breakpoint-medium) {
    .password-update {
 
       .container {
            form {
                width: 100%;
            }
       }
    }
 }