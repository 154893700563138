.videocall {
  @extend %grid;
  @extend %page;
  height: 100%;

  .container {
    @extend %grid;
    @include gridTemplateColumns(1fr);
    grid-template-rows: min-content;
    grid-gap: 24px;

    &--patient {
      @include gridTemplateRows(1fr);
    }

    .logo-container {
      @extend %flexCenter;

      .image {
        @extend %imgResponsive;
      }
    }

    .meeting {
      @include gridColumn(1, 2);
      @include gridRow(1, 2);
      overflow: auto;
    }

    .action-panel {
      @include gridColumn(1, 2);
      @include gridRow(2, 3);

      .accordion-list {
        @extend %grid;
        width: 100%;

        .accordion-wrapper {
          @include borderRadius(5px);
          @include boxShadow(
            0 24px 32px $color-black-200,
            0 16px 24px $color-black-200,
            0 4px 8px $color-black-200,
            0 0 1px $color-black-200
          );
          background-color: $color-white-100;
          padding: 16px;

          .accordion {
            @include borderRadius(20px);
            margin: 10px 0;

            .summary {
              @include borderRadius(20px);
              background-color: $color-blue-400;

              &.name {
                background-color: $color-red-100;
              }

              &.confirmed {
                background-color: $color-green-100;
              }

              .icon {
                fill: $color-white-100;
                height: 24px;
                width: 24px;
              }
            }

            .header {
              @extend %flex;
              @include flexAlignItems(center);
              @include flexJustifyContent(space-between);

              .icon {
                height: 32px;
                width: 32px;
              }

              .title {
                color: $color-white-100;
                font-size: 16px;
                font-weight: normal;
                margin: 0;
                margin-left: 10px;

                .required {
                  color: $color-red-100;
                  font-size: 24px;
                  font-weight: bold;
                  margin-left: 5px;
                }

                &.name {
                  margin-left: 42px;
                  font-weight: bold;
                }
              }
            }

            .MuiAccordionActions-root {
              padding: 0;
            }

            .MuiAccordionDetails-root {
              display: block;
            }

            .btn {
              @include borderRadius(0 0 20px 20px);
              background-color: $color-white-100;
              color: $color-red-100;
              font-size: 16px;
              font-weight: bold;
              padding: 10px 0;
              transition: none;
              width: 100%;

              &:active,
              &:focus,
              &.confirmed {
                background-color: $color-blue-400 !important;
                color: $color-white-100;
              }

              &.disabled {
                background-color: $color-grey-100;
                color: $color-white-100;
              }
            }
          }
        }

        .icon-btn-wrapper {
          @extend %flex;
          @include flexAlignItems(center);
          @include flexJustifyContent(space-between);
          padding: 8px;
          justify-content: center;

          .btn {
            @extend %btnBlueOutlined;
            min-width: initial;
            width: 100% !important;

            &--disabled {
              @extend %btnDisabled;
              cursor: not-allowed;
            }

            .MuiButton-label {
              font-size: 15px;
              margin: 3px;
            }
          }
        }
      }

      .MuiAccordion-root:before {
        left: auto;
      }

      .accordion-patient {
        @extend %flex;
        @include flexDirection(column);

        .detail-wrapper {
          padding: 10px 0;

          .detail {
            color: $color-blue-400;
            font-size: 16px;
            line-height: 18px;
            padding: 5px;

            .data {
              margin-left: 5px;
            }
          }
        }

        .button-hc {
          @extend %btnBlueOutlined;
        }

        .button-hc:disabled {
          background-color: #d3d3d3;
          color: #a0a0a0;
          border: 1px solid #ccc;
          cursor: not-allowed;
          opacity: 0.6;
        }

        .accordion {
          .header {
            @include boxShadow(
              0 24px 32px rgba(0, 0, 0, 0.04),
              0 16px 24px rgba(0, 0, 0, 0.04),
              0 4px 8px rgba(0, 0, 0, 0.04),
              0 0 1px rgba(0, 0, 0, 0.04)
            );
            @include borderRadius(20px);

            .title {
              color: $color-blue-400;
            }
          }

          .icon {
            height: 24px !important;
            width: 24px !important;
          }

          .heading {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: $color-blue-400;
          }

          .accordion-detail-wrapper {
            @extend %flex;
            @include flexDirection(column);

            .medical-record {
              margin: 10px 0;
              padding: 5px;

              .name {
                color: $color-red-50;
                font-size: 16px;
                font-weight: bold;
              }

              .chip {
                background-color: $color-red-50;
                color: $color-white-100;
                margin: 0 5px 5px 0;
              }
            }

            .data-record {
              color: $color-blue-400;
              font-size: 16px;
              line-height: 18px;
              padding: 5px;
            }
          }
        }
      }

      .plus-button {
        float: right;
        border-radius: 50%;
        min-width: 50px;
        background-color: $color-red-100;

        &:disabled {
          background-color: $color-grey-100;
        }
      }

      .save-button {
        float: right;
        border-radius: 50%;
        min-width: 50px;
        height: 45px;
        color: white;
        background-color: $color-red-100;

        &:disabled {
          background-color: $color-grey-100;
        }
      }
      .indications-buttons {
        color: white;
        font-weight: bold;
        border-radius: 10px;
      }
      .saved {
        background-color: $color-green-100;

        &:disabled {
          background-color: $color-grey-100;
        }
      }
      @keyframes shake {
        0% {
          transform: translateX(0);
        }

        25% {
          transform: translateX(-5px);
        }

        50% {
          transform: translateX(5px);
        }

        75% {
          transform: translateX(-5px);
        }

        100% {
          transform: translateX(0);
        }
      }
      .textAreaStyle {
        border: 1px solid rgba(0, 0, 0, 0.33);
      }
      .otherIndicationNameContainer {
        p {
          color: red;
          font-weight: bold;
          opacity: 0;
          transition: 1s ease-out all;
        }
        span {
          color: red;
          font-weight: bold;
        }
      }
      .showError {
        p {
          opacity: 1;
          transition: .5s ease-in all forwards;
        }
      }
      .shake {
        animation: shake 0.4s ease-in-out;

        &:focus {
          border-color: red;
        }
        /* Duración y suavidad de la animación */
      }
      .pt-10 {
        padding-top: 10px;
      }

      .h1-head {
        color: $color-red-50;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }

      .field {
        margin: 10px 0;
        width: 100%;
      }
      .field-small {
        margin: 10px 0;
        font-size: 15px !important;
        width: 100%;
      }
      .label-medication {
        color: gray;
        display: flex;
        font-size: 12px;
        padding: 10px 0px 0px 7px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-large) {
  .videocall {
    .container {
      grid-template-columns: 74% 26%;

      &--patient {
        @include gridTemplateColumns(1fr);
      }

      .meeting {
        @include gridColumn(1, 2);
        @include gridRow(1, 2);
      }

      .action-panel {
        @include gridColumn(2, 3);
        @include gridRow(1, 2);

        form {
          height: 100%;

          .accordion-list {
            @include gridTemplateRows(1fr auto);
            height: 100%;
            position: relative;
            width: 100%;

            .accordion-wrapper {
              @include gridColumn(1, 2);
              @include gridRow(1, 2);
              bottom: 0;
              overflow-x: hidden;
              overflow-y: auto;
              position: absolute;
              top: 0;
              .MuiAccordionSummary-content {
                margin: 0px;
              }
            }
          }

          .icon-btn-wrapper {
            @include gridColumn(1, 2);
            @include gridRow(2, 3);
            padding: 16px 8px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 76px) {
  .videocall {
    .container {
      max-width: none;
    }
  }
}

@media only screen and (min-width: 1520px) {
  .videocall {
    .container {
      grid-gap: 100px;
    }
  }
}
