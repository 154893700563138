.help {
   @extend %page;
   height: 100%;

   .header-professional {
      @extend %flex;
      @include flexDirection(row);
      @include flexAlignItems(center);

      img { 
         margin-right: 10px;
      }
   }
   .container {
      @extend %column;
      @include gridTemplateColumns(1fr);
      grid-gap: 24px;
      height: 100%;
      padding: 48px 24px;

      .header {
         @extend %flex;
         @include flexAlignItems(center);
         @include flexJustifyContent(center);
         color: $color-primary;

         img {
            width: 80px;
         }

         h2 {
            font-size: 22px;
            line-height: 100%;
            margin: 0;
         }
      }

      .accordion-wrapper {
         @include gridJustifySelf(center);
         width: 100%;

         svg {
            fill: $color-primary;
         }

         .title {
            color: $color-primary;
            font-size: 18px;
         }
         .content {
            color: #5c5c5c;
            margin-bottom: 20px;
            font-size: 18px;

            .disable-text{
               background-color: rgb(233, 229, 229);
               display: inline;
            }

            .functionality-bold{
               font-weight: bold;
               font-style: italic;
               color: black;
            }
         }
      }

      .contact-wrapper {
         @extend %column;
         height: min-content;

         .center-attention{
            @extend %flex;
            @include flexDirection(row);
            @include flexAlignItems(center);
            img{width: 80px};
         }

         .contact {
            @extend %flex;
            @include flexAlignItems(center);
            @include flexDirection(column);
            @include flexJustifyContent(start);
            color: $color-primary;

            .support{
               @extend %flex;
               @include flexDirection(row);
               @include flexAlignItems(center);
               img { width: 70px }
            }

            p {
               font-size: 18px;
               margin: 0;
               text-align: center;
            }
            span {
               font-size: 32px;
               font-weight: bold;
               margin: 0;
            }
         }

         .image {
            @include gridJustifySelf(center);
            width: 100px;
         }
      }
   }
}
.permitido{
   width: 100% !important;
   background-color: #228b22;
   color: #fff;
   text-align: center;
}
.nopermitido{
   width: 100%;
   background-color: #da2037;
   color: #fff;
   text-align: center;
}
@media screen and (min-width: $breakpoint-medium) {
   .help {
      .container {
         @include gridTemplateRows(auto 1fr);
         @include gridTemplateColumns(2fr 1fr);

         .header {
            @include flexJustifyContent(flex-start);
            @include gridColumn(1, 3);
            @include gridRow(1, 2);
         }

         .accordion-wrapper {
            @include gridColumn(1, 2);
            @include gridRow(2, 3);
         }

         .contact-wrapper {
            @include gridColumn(2, 3);
            @include gridRow(2, 3);
         }
      }
   }
}
