// Colors

// First tier
$color-black-100: #000;
$color-black-200: rgba(0, 0, 0, 0.04);
$color-blue-100: #428bca;
$color-blue-150:#268296;
$color-blue-200: #2a6496;
$color-blue-250: rgba(0, 44, 84, 0.25);
$color-blue-300: rgba(0, 44, 84, 0.75);
$color-blue-400: #002c54;
$color-green-50: #b0b400;
$color-green-100: #27ae60;
$color-green-200: #1b981b;
$color-green-300: #1c9e07;
$color-green-400: #35b577;
$color-grey-50: #ebebeb;
$color-grey-100: #bdbdbd;
$color-grey-200: #757575;
$color-orange-100: #ff6700;
$color-red-50: rgba(218, 32, 55, 0.75);
$color-red-100: #da2037;
$color-red-200: #cb3737;
$color-white-100: #fff;
$color-yellow-100: #c5c900;
$color-yellow-200: #f8b64c;
$color-yellow-300: #f6a300;
$color-purple-100: #ad618f;
// Blue MediLine
$color-primary: $color-blue-400;
// Red MediLine
$color-secondary: $color-red-100;

// Second tier
$color-container: $color-white-100;

$color-link: $color-blue-100;
$color-on-link: $color-blue-200;
$color-valued: $color-green-50;

$color-blue-mediline: $color-blue-400;
$color-red-mediline: $color-red-100;

$breakpoint-view: 1440px;
