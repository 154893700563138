.professional {
   @extend %grid;
   @extend %page;
   grid-gap: 10px;

   .turn-list,
   .virtual-guard,
   .liquidation-list {
      @extend %grid;
      @include gridTemplateRows(auto 1fr auto);
      grid-gap: 10px;
   }

   .button-wrapper {
      @extend %flex;
      @include flexJustifyContent(center);
   }

   .turn-list {
      &__list {
         ul {
            padding: 0;

            .list-item {
               @extend %grid;
               @include gridTemplateColumns(1fr 2fr 1fr);
               @include gridTemplateRows(1fr);
            }
         }

         .icon-wrapper {
            @extend %flex;
            @include flexDirection(row);
            @include flexJustifyContent(flex-end);

            .videocam-icon {
               svg {
                  fill: $color-green-200;
               }
            }
         }
      }
   }

   .center {
      @extend %grid;
      @include gridTemplateRows(repeat(3, auto));
      grid-gap: 10px;
      padding: 0 1px;
   }

   .header {
      @extend %flex;
      @include flexAlignItems(center);
      @include gridJustifySelf(center);

      img {
         width: 80px;
      }

      h2 {
         color: $color-primary;
         font-size: 22px;
         line-height: 100%;
         margin: 0;
         padding-right: 10px;
         text-align: center;
      }
   }

   .column {
      @extend %column;
   }

   .button-primary {
      @extend %flex;
      @include borderRadius(40px);
      @include flexAlignItems(center);
      @include flexJustifyContent(center);
      background-color: $color-primary;
      color: $color-white-100;
      padding: 10px 30px;
      width: 80%;

      &:focus,
      &:hover {
         background-color: lighten($color: $color-primary, $amount: 5);
      }

      &--active {
         background-color: lighten($color: $color-primary, $amount: 5);
      }
   }

   .title-see-all {
      @extend %flex;
      @include flexAlignItems(center);
      @include flexJustifyContent(space-between);
      color: $color-primary;

      h2 {
         font-size: 24px;
         line-height: 100%;
         margin: 0 0 5px 0;
      }

      span {
         font-size: 14px;
         font-weight: bold;
         line-height: 16px;
      }
   }

   .list-item {
      @include borderRadius(5px);
      @include boxShadow(
         0 24px 32px $color-black-200,
         0 16px 24px $color-black-200,
         0 4px 8px $color-black-200,
         0 0 1px $color-black-200
      );
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      cursor: pointer;

      &--selected {
         border-radius: 0 20px 20px 20px;
         background-color: $color-primary;
         &:focus,
         &:hover {
            background-color: lighten($color: $color-primary, $amount: 5);
         }

         &--active {
            background-color: lighten($color: $color-primary, $amount: 5);
         }
         span {
            color: $color-white-100;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
         }
      }

      &--liquidation {
         margin-top: 10px;
         margin-bottom: 10px;
         padding: 20px;
         border-radius: 0 20px 20px 20px;
         color: $color-white-100;
         background-color: $color-primary;
      }

      .item-text {
         min-width: 0;
         .item-chip > span {
            height: 24px;
            position: inherit;
            margin-top: 18px;
            margin-left: -78px;
            background-color: $color-red-100;
            color: $color-white-100;
            font-size: 12px;
            padding: 0 8px;
         }
         span {
            color: $color-secondary;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
         }

         &--date-time {
            p {
               font-size: 14px;
               font-weight: bold;
               line-height: 16px;
            }
         }

         &--name {
            span {
               color: $color-primary;
               font-weight: bold;
            }

            p {
               color: $color-primary;
            }
         }
      }

      .item-text-selected {
         min-width: 0;
         span {
            color: $color-white-100;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
         }

         &--date-time {
            p {
               font-size: 14px;
               font-weight: bold;
               line-height: 16px;
            }
         }

         &--name {
            span {
               color: $color-white-100;
               font-weight: bold;
            }

            p {
               color: $color-white-100;
            }
         }
      }
   }

   .virtual-guard {
      @include gridTemplateRows(auto auto 1fr);
      .switch {
         @include borderRadius(25px);
         @include boxShadow(
            0 10px 20px rgba(0, 0, 0, 0.04),
            0 2px 6px rgba(0, 0, 0, 0.04),
            0 0px 1px rgba(0, 0, 0, 0.04)
         );
         @include gridJustifySelf(center);
         display: inline-block;
         height: 48px;
         margin: 10px 0;
         min-width: 300px;
         position: relative;

         & input {
            display: none;
            &:checked + .slider {
               background-color: $color-green-100;

               & .on {
                  display: block;
               }
               & .off {
                  display: none;
               }
               &:before {
                  @include transform(translateX(252px));
               }
            }

            &:focus + .slider {
               box-shadow: 0 0 1px $color-blue-100;
            }
         }

         .slider {
            @include transition(0.4s);
            background-color: $color-red-100;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;

            &.round {
               @include borderRadius(25px);

               &:before {
                  @include borderRadius(50%);
               }
            }

            &:before {
               @include transition(0.4s);
               background-color: $color-white-100;
               bottom: 8px;
               height: 32px;
               left: 8px;
               position: absolute;
               width: 32px;
               content: '';
            }

            &.disabled {
               background-color: $color-grey-100;
            }
         }

         .label {
            @extend %flexCenter;
            @include flexDirection(row);
            font-size: 14px;
            line-height: 16px;

            .label-icon {
               margin-right: 10px;
            }
         }

         .on,
         .off {
            @include transform(translate(-50%, -50%));
            color: $color-white-100;
            font-size: 10px;
            left: 50%;
            top: 50%;
            position: absolute;
         }

         .on {
            display: none;
         }
      }
      .response {
         @extend %flex;
         @include flexAlignItems(center);
         @include flexDirection(column);
         color: $color-red-100;
         font-size: 16px;
         line-height: 21px;
         margin: 20px 0;
         min-height: 110px;

         .message {
            p {
               margin: 0;
            }
            
         }
      }
   }

   .virtual-guard-button {
      @extend %flex;
      @include borderRadius(40px);
      @include flexAlignItems(center);
      @include flexJustifyContent(center);
      background-color: $color-red-100;
      color: $color-white-100;
      font-size: 14px;
      height: 48px;
      line-height: 16px;
      width: 300px;
      padding: 10px 30px;
   }

   .virtual-guard-button-call-response {
      @include borderRadius(100%);
      background-color: $color-green-100;
      height: 50px;
      padding-left: 5px;
      color: $color-white-100;
   }
   .virtual-guard-button-call-response:hover {
      @include borderRadius(100%);
      background-color: $color-green-100;
      height: 50px;
      padding-left: 5px;
      color: $color-white-100;
   }

   .virtual-guard-button-send-response {
      @include borderRadius(100%);
      background-color: $color-blue-100;
      height: 50px;
      padding-left: 5px;
      color: $color-white-100;
   }
   .virtual-guard-button-send-response:hover {
      @include borderRadius(100%);
      background-color: $color-blue-100;
      height: 50px;
      padding-left: 5px;
      color: $color-white-100;
   }
   .virtual-guard-button-consultation-response {
      @include borderRadius(100%);
      background-color: #DC8079;
      height: 50px;
      padding-left: 5px;
      color: $color-white-100;
   }
   .virtual-guard-button-consultation-response:hover {
      @include borderRadius(100%);
      background-color: #DC8079;
      height: 50px;
      padding-left: 5px;
      color: $color-white-100;
   }
}

.liquidation {
   @extend %grid;
   @extend %page;
   grid-gap: 10px;

   .column {
      @extend %column;

      &--information {
         @include boxShadow(none);
         border: 0.5px solid $color-primary;
      }
   }

   .header {
      @extend %flex;
      @include flexAlignItems(center);

      img {
         width: 80px;
      }

      h2 {
         color: $color-primary;
         font-size: 22px;
         line-height: 100%;
         margin: 0;
         text-align: center;
      }
   }

   .chart {
      overflow: hidden;
   }

   .payment {
      @extend %grid;
      grid-gap: 10px;

      .header {
         height: 32px;

         h3 {
            color: $color-primary;
            font-size: 20px;
            line-height: 100%;
            margin: 0;
         }
      }

      .filter {
         .button-wrapper {
            width: 100%;
            margin: 10px 0;

            .button {
               font-size: 12px;
               height: auto;
               width: 100%;

               &--active {
                  background-color: $color-primary;
                  color: $color-white-100;
               }
            }
         }

         .input-wrapper {
            @extend %grid;
            @include gridTemplateColumns(repeat(2, 1fr));
            grid-gap: 10px;

            .input {
               margin: 0;
            }
         }
      }

      .list {
         .list-item {
            @extend %grid;
            @include borderRadius(5px);
            @include gridTemplateColumns(repeat(3, 1fr));
            background-color: $color-blue-300;
            margin: 10px 0;
            padding: 0 10px;
            cursor: pointer;

            .item-text {
               color: $color-white-100;

               &--query-type,
               &--query-length {
                  span {
                     font-size: 12px;
                     line-height: 14px;
                  }
               }

               &--query-type {
                  span {
                     text-align: left;
                  }
               }

               &--query-length {
                  span {
                     text-align: center;
                  }
               }

               &--price {
                  @include gridColumn(3, 4);
                  span {
                     font-size: 20px;
                     line-height: 48px;
                     text-align: right;
                  }
               }
            }

            &--total {
               background-color: $color-blue-400;
            }
         }
      }
   }

   .information {
      @extend %grid;
      grid-gap: 10px;

      .header {
         @extend %flex;
         @include flexAlignItems(center);
         @include flexJustifyContent(space-between);

         h2 {
            color: $color-primary;
            font-size: 22px;
            line-height: 100%;
            margin: 0;
         }

         h3 {
            color: $color-primary;
            font-size: 20px;
            line-height: 100%;
            margin: 0;
         }
      }

      .data,
      .billing {
         .header {
            height: 32px;
         }
      }

      .button {
         @include borderRadius(25px);
         border-color: $color-secondary;
         color: $color-secondary;
         height: auto;
         width: 150px;

         &--upload {
            float: right;
         }
      }

      .list .list-item {
         padding: 0;
      }
   }
}

.calendar-professional {
   @extend %page;
   grid-gap: 10px;
   height: 100%;

   &__container {
      @extend %grid;
      @include borderRadius(5px);
      @include boxShadow(
         0 24px 32px $color-black-200,
         0 16px 24px $color-black-200,
         0 4px 8px $color-black-200,
         0 0 1px $color-black-200
      );
      grid-gap: 10px;
      height: 100%;
      padding: 16px 24px;

      .schedule-title {
         @extend %flex;
         @include flexAlignItems(center);
         @include gridJustifySelf(center);

         img {
            width: 100px;
         }

         h2 {
            color: $color-primary;
            font-size: 24px;
            line-height: 100%;
            margin: 0 0 5px 0;
            text-align: center;
         }
      }

      .schedule {
         @extend %flexCenter;
         @include flexDirection(column);
         @include gridJustifySelf(center);
         width: 100%;

         h3 {
            font-size: 22px;
            line-height: 100%;
            margin: 10px 0;
         }

         button {
            margin: 20px 0;
         }

         ul {
            margin-top: 10px;
         }
      }
   }
}

@media screen and (min-width: $breakpoint-large) {
   .professional {
      @include gridTemplateColumns(1fr 1fr 1fr 1fr);
      height: 100%;

      .column {
         .title-see-all {
            margin-top: 10px;
         }
      }

      .turn-list {
         &__list {
            height: 100%;
            overflow: auto;
            position: relative;

            ul {
               bottom: 0;
               left: 0;
               position: absolute;
               right: 0;
               top: 0;
            }
         }
      }
      .response {
         .message {
            p {
               text-align: left;
               width: 100%;
            }
         }
      }
   }

   .liquidation {
      @include gridTemplateColumns(repeat(3, 33%));
      @include gridTemplateRows(auto 1fr);
      height: 100%;

      .column {
         @include gridRow(2, 3);
      }

      .payment,
      .information {
         @include gridTemplateRows(100px repeat(2, 1fr));
      }
   }

   .calendar-professional {
      &__container {
         @include gridTemplateColumns(1fr 2fr);
         @include gridTemplateRows(auto 1fr);

         .schedule-title {
            @include gridColumn(1, 2);
            @include gridJustifySelf(flex-start);
            @include gridRow(1, 2);
            padding: 0 10px;
         }

         .schedule {
            @include flexJustifyContent(flex-start);
            @include flexAlignItems(flex-start);
            @include gridColumn(1, 2);
            @include gridJustifySelf(left);
            @include gridRow(2, 3);
            margin-bottom: 10px;
            padding: 0 25px;
         }

         .calendar {
            @include gridColumn(2, 3);
            @include gridRow(2, 3);
            padding: 0 10px;
         }
      }
   }
}
