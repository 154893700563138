.my-appointments {
   @extend %grid;
   @extend %page;
   @include gridTemplateRows(auto 1fr);
   grid-gap: 24px;
   height: 100%;

   .header {
      @extend %flex;
      @include flexAlignItems(center);
      @include flexDirection(column);

      .image-title {
         @extend %flex;
         @include flexAlignItems(center);

         img {
            width: 80px;
         }

         h2 {
            font-size: 22px;
            line-height: 100%;
            margin: 0;
         }
      }

      .btn-group {
         .btn {
            &.active {
               background-color: $color-blue-mediline;
               color: white;
            }
         }
      }
   }

   .container {
      @extend %column;
      height: 100%;
      padding: 48px 24px;

      .my-appointments-list {
         @extend %grid;
         height: 100%;
         width: 100%;

         &--time-availability {
            @include gridTemplateRows(auto 1fr);
            grid-gap: 16px;

            .btn-wrapper {
               @include gridJustifySelf(center);
               padding: 0 16px;

               .schedule-create-btn {
                  @include borderRadius(25px);
                  background-color: $color-secondary;
                  color: $color-white-100;
                  height: auto;
                  font-size: 16px;
                  padding: 8px 16px;
               }
            }
         }

         .list {
            position: relative;

            ul {
               bottom: 0;
               left: 0;
               overflow: auto;
               padding: 0 16px;
               position: absolute;
               right: 0;
               top: 0;

               .list-item {
                  @extend %grid;
                  @include borderRadius(0 40px 40px 40px);
                  @include boxShadow(
                     0 24px 32px $color-black-200,
                     0 16px 24px $color-black-200,
                     0 4px 8px $color-black-200,
                     0 0 1px $color-black-200
                  );
                  @include gridTemplateColumns(auto auto 1fr auto);
                  cursor: pointer;
                  margin-bottom: 10px;
                  grid-gap: 10px;

                  .item-chip {
                     @include borderRadius(20px);
                     height: 24px;

                     &.programmed {
                        background-color: $color-yellow-200;
                     }

                     &.cancelled,
                     &.finished {
                        background-color: $color-grey-200;
                     }

                     span {
                        color: $color-white-100;
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 120%;
                        padding: 0 8px;
                     }
                  }

                  .item-text {
                     min-width: 0;

                     span {
                        color: $color-primary;
                        font-size: 14px;
                        line-height: 16px;
                     }
                  }
                  .icon-wrapper {
                     .videocam-icon {
                        svg {
                           fill: $color-green-200;
                        }

                        &.disabled {
                           svg {
                              fill: $color-grey-100;
                           }
                        }
                     }
                  }

               }
            }
         }

         .empty {
            @extend %flex;
            @include flexDirection(column);

            .message {
               color: $color-primary;
               font-size: 14px;
               line-height: 120%;
               margin-bottom: 24px;
               max-width: 500px;
               width: auto;
            }
         }
      }
   }
}

@media screen and (min-width: $breakpoint-large) {
   .my-appointments {
      .header {
         @include flexDirection(row);
         @include flexJustifyContent(space-between);

         .image-title {
            @include flexDirection(row);
         }
      }

      .container {
         .my-appointments-list {
            .btn-wrapper {
               @include gridJustifySelf(left);
            }
         }
      }
   }
}
