body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
width: 7px;
height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #002c54;
  border: 1px inset #faf5f5;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #014f93;
}
::-webkit-scrollbar-thumb:active {
  background: #002c54;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #e0e0e0;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


div.react-tel-input > input{
  border: 0px solid #CACACA !important;
  border-bottom: 1px solid #CACACA !important;
  border-radius: 1px !important;
  width: 200px !important;
}