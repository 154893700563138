.pharmacy-modal {
   @extend %flex;
   @include flexDirection(column);
   height: 560px;

   .title-search {
      @extend %flex;
      @include flexDirection(row);

      &__title {
         @extend %flex;
         @include flexDirection(row);
         width: 450px;
         height: 80px;

         .iconPharmacy {
            width: 90px;
            height: 85px;
         }

         h2 {
            height: 70px;
         }
      }

      &__search {
         margin-top: 20px;
         height: 25px;
         margin-left: 85px;
      }
      &__search_text {
         width: 80%;
      }
   }

   .pharmacies {
      @extend %flex;
      @include flexDirection(row);
      overflow: scroll;
      height: 100%;
      width: 100%;
      margin-top: 10px;
      margin-left: 65px;

      .list {
         position: relative;

         ul {
            bottom: 0;
            left: 0;
            overflow: auto;
            padding: 0 16px;
            position: relative;
            right: 0;
            top: 0;

            .list-item-pharmacy {
               @extend %grid;
               @include borderRadius(0 40px 40px 40px);
               @include boxShadow(
                  0 24px 32px $color-black-200,
                  0 16px 24px $color-black-200,
                  0 4px 8px $color-black-200,
                  0 0 1px $color-black-200
               );
               @include gridTemplateColumns(1fr auto);
               @include gridTemplateRows(1fr 1fr 1fr auto);
               background-color: $color-white-100;
               color: $color-blue-400;
               cursor: pointer;
               font-weight: bold;
               width: 550px;
               height: auto;
               margin-bottom: 20px;

               .name-pharmacy {
                  @extend %flex;
                  @include flexDirection(row);

                  &__name {
                     margin-right: 5px;
                  }

                  &__departament {
                     margin-left: 5px;
                  }
               }

               .direction-telephone {
                  @extend %flex;
                  @include flexDirection(column);
                  font-size: small;
                  font-weight: normal;
                  margin-left: 10px;
                  margin-top: 10px;

                  .direction {
                     @extend %flex;
                     @include flexDirection(row);

                     &__number {
                        margin-left: 5px;
                     }
                  }
                  .telephone {
                     @extend %flex;
                     @include flexDirection(row);

                     &__number {
                        margin-left: 5px;
                     }
                  }
               }
            }
         }
      }

      .empty {
         margin-left: 20px;
      }
   }
}

@media screen and (min-width: $breakpoint-medium) {
   .pharmacies {
      .list {
         ul {
            .list-item-pharmacy {
               .name-pharmacy {
                  .list-item-prescription {
                     .presc-data {
                        .id-state {
                           @include gridTemplateColumns(1fr 2fr);
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
