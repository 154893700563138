.gmail-callback {
    @extend %page;
    height: 90vh;

    .container {
        @extend %container;
        grid-gap: 0;

        .box {
            @extend %flexCenter;
            @include flexDirection(column);

            img {
                width: 150px;
            }    

            h1 {
                color: $color-blue-mediline;
                font-size: 1.5em;
                font-weight: bold;
                margin-bottom: 16px;
            }

            .button-primary {
                @include borderRadius(40px);
                @include flexAlignItems(center);
                @include flexJustifyContent(center);
                background-color: $color-primary;
                color: $color-white-100;
                padding: 10px 30px;
                margin-top: 30px;
                width:180px;
                font-size: 0.875rem;
                
                &:focus,
                &:hover {
                    background-color: lighten($color: $color-primary, $amount: 5);
                }
                
                &--active {
                   background-color: lighten($color: $color-primary, $amount: 5);
                }
                
                .MuiSvgIcon-root {
                    font-size: 0.875rem;
                    margin-left: 10px;
                }
             }

        }
    }
}