.call-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.local {
  background: url("../../../assets/img/user.svg") no-repeat center center;
  -webkit-background-size: 63%;
  -moz-background-size: 63%;
  -o-background-size: 63%;
  background-size: 63%;
  background-color: #002a55;
  border: 2px solid #002a55;
  border-radius: 5px;
  overflow: hidden;
  height: 15vh;
  width: 10vw;
  right: 15px;
  top: 15px;
  z-index: 1;
}
@media (max-width: 480px) {
  .local {
    height: 15vh;
    width: 20vw;
    border: 2px solid #002a55;
  }
}

.remote {
  background: url("../../../assets/img/user.svg") no-repeat center center;
  -webkit-background-size: 63%;
  -moz-background-size: 63%;
  -o-background-size: 63%;
  background-size: 63%;
  background-color: #dbe9f7;
  /* padding: 0px;
  margin: 0px; */
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  /* height: 500px;
  width: 500px; */
}

.fa,
.fas {
  font-size: 32px;
  padding: 10px 25px;
}

.full {
  background-color: white;
  border-radius: 5px;
  font-size: 24px;
  padding: 5px 10px;
}

.roomMessage {
  align-items: center;
  background: burlywood;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

#btnCam,
#btnMic,
#btnPlug,
#btnVirtualBackground {
  cursor: pointer;
}
