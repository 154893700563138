.appointment {
   @extend %grid;
   @include gridColumn(1, 3);
   @include gridRow(1, 3);
   @include flexJustifyContent(center);
   grid-gap: 10px;
   min-width: 400px;

   .title {
      @include gridJustifySelf(center);
      color: $color-primary;
      font-size: 24px;
      font-weight: 600;
      line-height: 120%;
   }

   .professional {
      @include gridJustifySelf(center);
      width: auto;

      .text {
         span {
            color: $color-primary;
            font-size: 14px;
            font-weight: bold;
         }

         p {
            color: $color-black-100;
            max-width: 300px;
         }
      }
   }

   .turn {
      @include borderRadius(0 20px 20px 20px);
      @include boxShadow(
         0 10px 20px rgba(0, 0, 0, 0.04),
         0 2px 6px rgba(0, 0, 0, 0.04),
         0 0px 1px rgba(0, 0, 0, 0.04)
      );
      @include gridJustifySelf(center);
      padding: 8px 24px;
      width: auto;

      .text {
         span,
         p {
            color: $color-secondary;
            font-size: 18px;
            font-weight: bold;
            line-height: 21px;
         }
      }
   }

   .message {
      color: $color-primary;
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 24px;
      text-align: center;
      width: auto;
   }

   .button {
      @include borderRadius(25px);
      background-color: $color-primary;
      color: $color-white-100;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
      padding-top: 16px;
      padding-bottom: 16px;
      width: auto;

      &:hover {
         background-color: lighten($color: $color-primary, $amount: 5);
      }
   }
}
