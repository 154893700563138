.confirmation-modal {
  @extend %flex;
  @include flexDirection(column);
  height: 350px;
  width: 400px;
  align-items: center;
  .image {
    width: 150px;
  }
  .sent {
    font-weight: bold;
    font-size: x-large;
    color: $color-blue-400;
    margin-bottom: 40px;
  }
  .text {
    color: $color-blue-400;
  }
  .text2 {
    color: $color-blue-400;
    margin-bottom: 25px;
  }
  .button {
    @extend %flex;
    justify-content: center;
    height: 25px;
    width: 170px;
    background-color: $color-red-100;
    border-radius: 10px;
    text-align: center;
    .inicio {
      color: white;
      font-size: medium;
    }
  }
}

.recomendations_modal {
  display: flex;
  gap: 20px;

  .item-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;

    .icon-container {
      width: fit-content;
      border: 3px solid #002c54;
      border-radius: 35px;
      padding: 7px;

      img {
        height: 40px;
        width: 40px;
        padding: 5px;
      }
    }
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 0px;
    height: 350px;
    overflow: scroll;
  }
}
