.login-slider {
   // This is to avoid flickering effect by loading images previously
   @for $i from 1 through 5 {
      background-image: url('../../assets/img/login-background-#{$i}.png');
   }
   @extend %flexCenter;
   @extend %noFlick;
   @include animation(slideBackground 50s linear infinite);
   background-size: cover;
   height: 100%;
   position: relative;

   &__logo {
      left: 100px;
      position: absolute;
      top: 100px;
      width: 200px;
   }

   .icon-text {
      @extend %flex;
      @include flexAlignItems(center);
      @include flexDirection(column);

      .slider-icon {
         height: 90px;
         img {
            // This is to avoid flickering effect by loading images previously
            @for $i from 1 through 5 {
               content: url('../../assets/img/login-icon-#{$i}.svg');
            }
            @include animation(slideIcon 50s linear infinite);
            width: 90px;
         }
      }

      .slider-text {
         color: $color-white-100;
         font-size: 1.5em;
         font-weight: bold;
         margin-top: 8px;

         &:before {
            @include animation(slideText 50s linear infinite);
            content: 'Programá un turno';
         }
      }
   }
}

@keyframes slideIcon {
   0% {
      content: url('../../assets/img/login-icon-1.svg');
      opacity: 1;
   }
   18% {
      opacity: 1;
   }
   19% {
      content: url('../../assets/img/login-icon-1.svg');
      opacity: 0.5;
   }
   20% {
      content: url('../../assets/img/login-icon-2.svg');
      opacity: 1;
   }
   28% {
      opacity: 1;
   }
   39% {
      content: url('../../assets/img/login-icon-2.svg');
      opacity: 0.5;
   }
   40% {
      content: url('../../assets/img/login-icon-3.svg');
      opacity: 1;
   }
   58% {
      opacity: 1;
   }
   59% {
      content: url('../../assets/img/login-icon-3.svg');
      opacity: 0.5;
   }
   60% {
      content: url('../../assets/img/login-icon-4.svg');
      opacity: 1;
   }
   78% {
      opacity: 1;
   }
   79% {
      content: url('../../assets/img/login-icon-4.svg');
      opacity: 0.5;
   }
   80% {
      content: url('../../assets/img/login-icon-5.svg');
      opacity: 1;
   }
   98% {
      opacity: 1;
   }
   99% {
      content: url('../../assets/img/login-icon-5.svg');
      opacity: 0.5;
   }
   100% {
      content: url('../../assets/img/login-icon-1.svg');
      opacity: 1;
   }
}

@keyframes slideText {
   0% {
      content: 'Programá un turno';
      opacity: 1;
   }
   18% {
      opacity: 1;
   }
   19% {
      content: 'Programá un turno';
      opacity: 0.5;
   }
   20% {
      content: 'Hacé una videoconsulta';
      opacity: 1;
   }
   38% {
      opacity: 1;
   }
   39% {
      content: 'Hacé una videoconsulta';
      opacity: 0.5;
   }
   40% {
      content: 'Recibí tu receta electrónica';
      opacity: 1;
   }
   58% {
      opacity: 1;
   }
   59% {
      content: 'Recibí tu receta electrónica';
      opacity: 0.5;
   }
   60% {
      content: 'Comprá tu medicamento';
      opacity: 1;
   }
   78% {
      opacity: 1;
   }
   79% {
      content: 'Comprá tu medicamento';
      opacity: 0.5;
   }
   80% {
      content: 'Recibí tu medicamento en casa';
      opacity: 1;
   }
   98% {
      opacity: 1;
   }
   99% {
      content: 'Recibí tu medicamento en casa';
      opacity: 0.5;
   }
   100% {
      content: 'Programá un turno';
      opacity: 1;
   }
}

@keyframes slideBackground {
   0% {
      background-image: url('../../assets/img/login-background-1.png');
      background-position: 0 0;
      opacity: 1;
   }
   18% {
      background-position: 100% 100%;
      opacity: 1;
   }
   19% {
      background-image: url('../../assets/img/login-background-1.png');
      opacity: 0.5;
   }
   20% {
      background-image: url('../../assets/img/login-background-2.png');
      background-position: 0 0;
      opacity: 1;
   }
   38% {
      background-position: 100% 100%;
      opacity: 1;
   }
   39% {
      background-image: url('../../assets/img/login-background-2.png');
      opacity: 0.5;
   }
   40% {
      background-image: url('../../assets/img/login-background-3.png');
      background-position: 0 0;
      opacity: 1;
   }
   58% {
      background-position: 100% 100%;
      opacity: 1;
   }
   59% {
      background-image: url('../../assets/img/login-background-3.png');
      opacity: 0.5;
   }
   60% {
      background-image: url('../../assets/img/login-background-4.png');
      background-position: 0 0;
      opacity: 1;
   }
   78% {
      background-position: 100% 100%;
      opacity: 1;
   }
   79% {
      background-image: url('../../assets/img/login-background-4.png');
      opacity: 0.5;
   }
   80% {
      background-image: url('../../assets/img/login-background-5.png');
      background-position: 0 0;
      opacity: 1;
   }
   98% {
      background-position: 100% 100%;
      opacity: 1;
   }
   99% {
      background-image: url('../../assets/img/login-background-5.png');
      opacity: 0.5;
   }
   100% {
      background-image: url('../../assets/img/login-background-1.png');
      background-position: 0 0;
      opacity: 1;
   }
}
