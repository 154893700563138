.modal1 .MuiPaper-root {
  @include borderRadius(0 25px 25px);
  margin: 0 !important;
  padding: 20px;
  width: 90vw !important;
  .MuiDialogContent-root {
    overflow: hidden;
    padding: 0 !important;
  }

  .close {
    text-align: end;
    width: 100%;
    color: aqua;
  }

  // This is for EmergencyModal for Patient Dashboard
  .emergency-modal1 {
    @extend %grid;
    @include gridTemplateColumns(1fr);
    @include gridTemplateRows(auto 1fr);
    grid-gap: 20px;
    width: 100%;

    &__icon {
      margin-right: 15px;
      width: 45px;
    }

    &__title {
      @extend %flex;
      @include flexAlignItems(right);
      @include flexJustifyContent(left);
      @include flexDirection(row);
      margin-bottom: 30px;

      h2 {
        color: $color-blue-300;
        font-family: sans-serif !important;
        font-size: 30px !important;
        font-weight: bold;
        width: 100%;
      }
    }

    &__description {
      color: $color-primary;
      h3 {
        display: block;
        font-size: 1.17em !important;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold !important;
      }
    }

    &__phone-numbers-wrapper {
      .phone-numbers-item {
        @extend %flex;
        @include flexDirection(column);
        background-color: $color-secondary;
        border-radius: 50px;
        color: $color-white-100;
        justify-content: left;
        margin-top: 5px;
        padding: 0px;
        width: 100% !important;

        &__number {
          font-size: 27px;
          font-weight: bold;
          margin-right: 15px;
        }
      }
      .phone-number-icon {
        font-size: 30px;
        margin-left: 20px;
      }
    }
  }
  .pharmacy-modal {
    &__icon {
      width: 90px;
    }
    &__title {
      @extend %flex;
      @include flexDirection(row);

      h2 {
        font-size: medium;
        width: 230px;
      }
    }
  }

  .increase-modal {
    height: 100%;
    width: 100%;
  }
}

.modal2 .MuiPaper-root {
  @include borderRadius(0 25px 25px);
  margin: 0 !important;
  padding: 20px;
  .MuiDialogContent-root {
    overflow: hidden;
    padding: 0 !important;
  }

  .close {
    text-align: end;
    width: 100%;
    color: aqua;
  }
  overflow-y: auto;
  max-height: 100vh;

}
.modal1.full-screen {
  .MuiPaper-root {
    height: 100%;
    width: 100%;
    margin: 50px;
  }
}

@media screen and (min-width: $breakpoint-medium) {
  .modal1 {
    width: 100vw;
    .emergency-modal1 {
      @include gridTemplateColumns(repeat(2, 1fr) !important);
      @include gridTemplateRows(1fr !important);
      width: 550px !important;
    }
  }
  .modal2 {
    width: 100vw;
  }
}
