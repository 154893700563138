/* En tu archivo de estilos CSS o en línea */
.chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 30%;
    z-index: 1000;
  }

  .chat-container-bar {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .chat-bar {
    background-color: #002c54;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 100%;
  }
  
  .open-button {
    position: relative;
    bottom: 8px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }

  .open-button:hover{
    background-color: transparent;
  }
  
  .chat-overlay {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1000;
  }
  
  .close-button {
    position: absolute;
    color: #828282;
    top: -18px;
    right: -5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1000;
  }

  .close-button:hover {
    background-color: transparent;
}