.active-professionals-list {
  width: 35rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    h2 {
      color: #002c54;
      font-size: 1.2rem;
      line-height: 100%;
      margin: 0;
      padding-right: 10px;
      text-align: center;
      font-weight: bold;
      white-space: nowrap;
      width: 9rem;
    }
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.01rem;
    .last-active {
      display: flex;
      gap: .5rem;
      width: 9rem;
    }
    &.disabled {
      color: grey;
    }
    span{
      width: 10rem;
    }
  }
}
