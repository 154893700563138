.layout {
  @extend %containerBreakpoint;
  @extend %grid;
  @include gridTemplateRows(80px 1fr);
  min-height: 100vh;

  .header {
    @include gridRow(1, 2);
    div{
      max-width: 100%;
    }
  }

  .main {
    @include gridRow(2, 3);
  }
}
.version {
  text-align: right;
  font-size: 10px;
  margin-right: 25px;
  margin-top: 3%;
}
//TODO:Fix for the error screen on dev
iframe {
  display: none;
}

.empty-layout {
  height: 100vh;

  .main {
    height: 100%;
  }
}


@media only screen and (min-width: 1520px) {
  .layout {
    margin-left: 100px;
  }
}