%button {
   @extend %font;
   @include borderRadius(25px);
   font-size: 0.9em;
   line-height: 21px;
   max-width: 350px;
   min-width: 200px;
   text-transform: initial;
}

@mixin btn($color) {
   @extend %button;
   background-color: $color;
   color: white;

   &:active,
   &:focus,
   &:hover {
      background-color: lighten($color: $color, $amount: 5);
   }
}

@mixin btnOutlined($color) {
   @extend %button;
   background-color: white;
   border: 1px solid $color;
   color: $color;
   font-weight: bold;

   &:active,
   &:focus,
   &:hover {
      background-color: rgb(245, 244, 250);
   }
}

%btnBlue {
   @include btn($color-blue-mediline);
}

%btnRed {
   @include btn($color-red-mediline);
}

%btnBlueOutlined {
   @include btnOutlined($color-blue-mediline);
}

%btnRedOutlined {
   @include btnOutlined($color-red-mediline);
}


%btnDisabled {
   @include btn($color-grey-100);
   background-color: $color-grey-100 !important;
   border: 1px solid $color-grey-100 !important;
   color: white;
   cursor: not-allowed;
   font-weight: bold;

   &:active,
   &:focus,
   &:hover {
      background-color: $color-grey-100 !important;
      border: 1px solid $color-grey-100 !important;
      color: white !important;
   }
}
