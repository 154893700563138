.terms-and-conditions {
   @extend %page;
   height: 100%;

   .container {
      @extend %column;
      @include gridTemplateRows(auto 1fr);
      grid-gap: 24px;
      height: 100%;
      padding: 48px 24px;

      .header {
         @extend %flex;
         @include flexAlignItems(center);
         @include gridJustifySelf(center);
         color: $color-primary;

         img {
            width: 80px;
         }

         h2 {
            font-size: 22px;
            line-height: 100%;
            margin: 0;
         }
      }

      .text-wrapper {
         @extend %column;
         @include gridJustifySelf(center);
         height: 100%;
         position: relative;
         width: 100%;

         .text {
            bottom: 0;
            left: 0;
            letter-spacing: 0.5px;
            line-height: 20px;
            overflow: auto;
            padding: 16px 24px;
            position: absolute;
            right: 0;
            top: 0;
         }
      }
   }
}

@media screen and (min-width: $breakpoint-medium) {
   .terms-and-conditions {
      .container {
         .text-wrapper {
            width: 75%;
         }
      }
   }
}
