.patient {
  @extend %grid;
  @extend %page;
  grid-gap: 10px;

  .list {
    overflow: auto;
    padding: 0 10px;

    ul {
      .list-item {
        @include borderRadius(5px);
        @include boxShadow(
          0 24px 32px $color-black-200,
          0 16px 24px $color-black-200,
          0 4px 8px $color-black-200,
          0 0 1px $color-black-200
        );
        cursor: pointer;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .filter-result {
    @extend %grid;
    @include borderRadius(5px);
    @include boxShadow(
      0 24px 32px $color-black-200,
      0 16px 24px $color-black-200,
      0 4px 8px $color-black-200,
      0 0 1px $color-black-200
    );
    grid-gap: 10px;
    padding: 16px 24px;

    .header {
      @extend %flex;
      @include flexAlignItems(center);
      @include gridJustifySelf(center);
      color: $color-primary;
      height: 90px;

      img {
        width: 80px;
      }

      hgroup {
        h2 {
          font-size: 22px;
          line-height: 100%;
          margin: 0 0 5px 0;
        }

        h3 {
          font-size: 14px;
          font-weight: normal;
          line-height: 16px;
          margin: 0;
        }
      }
    }

    .filter {
      @extend %grid;
      @include gridTemplateRows(repeat(3, auto));
      grid-gap: 10px;

      .select-wrapper {
        @extend %flex;
        @include flexDirection(column);
        @include gridJustifySelf(center);
        height: 110px;
        max-width: 450px;
        padding: 10px 0;
        width: 100%;

        .MuiFormLabel-root {
          margin-bottom: 8px;
          &.MuiInputLabel {
            &-formControl {
              top: 5px;
              left: 10px;
            }

            &-shrink {
              transform: translate(0, -10px) scale(1);
            }
          }
        }
        .prepaid-health {
          @extend %flex;
          @include flexDirection(column);
          margin-top: 8px;
          .MuiFormLabel-root {
            font-weight: bold;
          }
        }

        .select {
          @include borderRadius(0 20px 20px 20px);
          @include boxShadow(
            0 24px 32px rgba(0, 0, 0, 0.04),
            0 16px 24px rgba(0, 0, 0, 0.04),
            0 4px 8px rgba(0, 0, 0, 0.04),
            0 0 1px rgba(0, 0, 0, 0.04)
          );
          font-size: 1rem;

          &.error {
            border: 1px solid $color-red-100;
            color: $color-red-100;
          }

          .MuiSelect-select.MuiSelect-select {
            @include borderRadius(0 20px 20px 20px);
            padding: 10px;
          }

          .MuiSelect-icon {
            margin-right: 5px;
          }
        }

        .helper-text {
          margin: 0;
          padding: 5px 10px;

          &.error {
            color: $color-red-100;
          }
        }
      }

      .button-wrapper {
        @extend %flex;
        @include flexDirection(column);
        @include gridJustifySelf(center);

        .filter-button {
          @extend %flex;
          @include borderRadius(40px);
          @include flexAlignItems(center);
          @include flexJustifyContent(space-between);
          background-color: $color-primary;
          border: none;
          color: $color-white-100;
          margin: 5px 0;
          outline: none;
          padding: 15px 30px;
          cursor: pointer;
          height: 85px;
          margin-top: 10px;

          &:focus,
          &:hover {
            background-color: lighten($color: $color-primary, $amount: 8);
          }

          &.active {
            background-color: lighten($color: $color-primary, $amount: 8);
          }

          &.disabled {
            background-color: $color-grey-100;
            pointer-events: none;

            .title-icon {
              svg {
                color: $color-white-100 !important;
              }
            }
          }

          .title-wrapper {
            @extend %flex;
            @include flexDirection(column);
            text-align: left;

            .title-icon {
              @extend %flex;
              @include flexAlignItems(center);
              font-size: 18px;
              font-weight: 600;
              line-height: 21px;

              svg {
                color: $color-green-200;
                margin-left: 5px;
                width: 18px;
              }
            }

            .subtitle {
              font-size: 12px;
              line-height: 14px;
              margin-top: 5px;
            }
          }

          .icon {
            font-size: 40px;
            font-weight: normal;
          }
        }

        .btn-emergencia {
          background-color: #da2037;
          border-radius: 100px;
          text-transform: capitalize;
          color: white;
          margin-top: 10px;
          width: 50px;
          height: 50px;
          padding: 20px;

          &__icon-emergency {
            fill: #002c54;
            width: 35px;
          }
        }

        .btn-emergencia:hover {
          background-color: #e84353;
        }
      }
    }

    // This class moves the specialties autocomplete to Result column at Patient Dashboard
    .specialties-to-result-column {
      display: grid;
      @include gridJustifySelf(center);
      @include gridAlignSelf(center);
      height: min-content;
      max-width: 450px;
      width: 100%;
    }

    .result {
      @extend %flex;
      @include flexDirection(column);
      max-height: 500px;

      &__title {
        @extend %flexCenter;
        padding: 0 10px 10px;

        h2 {
          color: $color-primary;
          font-size: 22px;
          line-height: 100%;
          margin: 10px 0;
        }
      }

      .default {
        @extend %flexCenter;
        @include flexDirection(column);

        .image {
          width: 150px;
        }

        .text {
          font-size: 16px;
          line-height: 120%;
          color: $color-red-100;
          text-align: center;
        }
      }

      .test-connection-container {
        @extend %flexCenter;
        @include flexDirection(column);
        @include flexJustifyContent(space-evenly);
        @include flexAlignItems(center);

        .test-connection {
          @extend %flexCenter;
          @include flexDirection(column);
          @include flexAlignItems(center);

          min-height: 222px;
          border: 1px solid grey;
          border-radius: 30px;
          max-width: 242px;
          padding: 15px;
        }
      }

      .empty {
        color: $color-red-100;
        font-size: 16px;
        line-height: 21px;
        margin: 20px 0;
        min-height: 110px;
        text-align: center;

        p {
          margin: 0;
        }
      }

      .list {
        height: 100%;

        .text-red {
          font-size: 16px;
          font-weight: bold;
          color: $color-red-100;
          text-align: center;
          padding: 30px;
        }

        .list-item {
          @include borderRadius(0 40px 40px 40px);

          .item-avatar {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }

          .item-text {
            span {
              font-size: 14px;
              font-weight: bold;
              color: $color-blue-400;
            }

            p {
              color: $color-black-100;
            }
          }

          .text-chip-wrapper {
            width: 100%;

            .item-text {
              margin-bottom: 0;

              span {
                @extend %flex;
                @include flexAlignItems(center);
              }
            }

            .item-icon {
              @include boxShadow(none);
              background-color: transparent;
              fill: $color-green-200;
              font-size: 14px;
              margin-left: 5px;
            }

            .item-chip {
              border: none;
              height: auto;
              margin: 6px 0;

              span {
                color: $color-green-200;
                font-weight: bold;
                padding: 0;
              }
            }
          }

          .item-icon {
            @include boxShadow(
              0 10px 20px rgba(0, 0, 0, 0.04),
              0 2px 6px rgba(0, 0, 0, 0.04),
              0 0 1px rgba(0, 0, 0, 0.04)
            );
            background-color: $color-white-100;
            margin-right: 0;
          }

          .item-right {
            @extend %flexCenter;
            @include flexDirection(column);

            .item-text {
              @extend %flexCenter;
              @include flexDirection(column);

              span {
                color: $color-primary;
                text-align: center;
              }

              p {
                color: $color-red-100;
                text-align: center;
              }
            }

            .item-chip {
              @include borderRadius(5px);
              height: 24px;

              span {
                color: $color-primary;
                font-size: 12px;
                line-height: 120%;
                padding: 0 8px;
              }
            }
          }
        }
      }
    }
  }

  .data {
    @extend %grid;
    @include borderRadius(5px);
    @include boxShadow(
      0 24px 32px $color-black-200,
      0 16px 24px $color-black-200,
      0 4px 8px $color-black-200,
      0 0 1px $color-black-200
    );
    @include gridTemplateRows(auto);
    grid-gap: 10px;
    padding: 16px 24px;

    .turn {
      @extend %flex;
      @include flexDirection(column);

      .title-wrapper {
        @extend %flex;
        @include flexAlignItems(center);
        @include flexDirection(row);
        @include flexJustifyContent(space-between);
        padding: 0 10px;
        width: 100%;

        .title-see-all {
          @extend %flex;
          @include flexAlignItems(center);
          @include flexDirection(row);
          color: $color-primary;
          gap: 10px;

          .image {
            width: 50px;
          }

          .title {
            color: $color-blue-400;
            cursor: pointer;
            font-size: 22px;
            line-height: 100%;
            margin: 10px 0;
            text-decoration: none;
            width: 70%;
          }

          span {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
          }

          button {
            margin-right: 24px;
          }
        }
      }

      .list {
        max-height: 250px;

        .list-item {
          @extend %grid;
          @include gridTemplateColumns(auto 1fr auto);
          grid-gap: 10px;

          .item-text {
            min-width: 0;
            span {
              color: $color-primary;
              font-size: 14px;
              line-height: 16px;
            }

            &--date-time {
              p {
                font-size: 14px;
                font-weight: bold;
                line-height: 16px;
              }
            }

            &--name {
              span {
                color: $color-primary;
                font-weight: bold;
              }

              p {
                color: $color-primary;
              }
            }
          }

          .item-chip {
            @include borderRadius(5px);
            border-color: $color-red-100;
            height: 24px;

            span {
              color: $color-red-100;
              font-size: 12px;
              font-weight: bold;
              line-height: 120%;
              padding: 0 8px;
            }

            &--accepted {
              border-color: $color-green-200;
              span {
                color: $color-green-200;
              }
            }
          }

          .icon-wrapper {
            .videocam-icon {
              svg {
                fill: $color-green-200;
              }

              &.disabled {
                svg {
                  fill: $color-grey-100;
                }
              }
            }
          }
        }
      }
    }

    .order {
      @extend %flex;
      @include flexDirection(column);

      &__title {
        @extend %flex;
        @include flexAlignItems(center);
        @include flexDirection(row);
        @include flexJustifyContent(space-between);
        padding: 0 10px;
        width: 100%;

        .title-wrapper {
          @extend %flex;
          @include flexAlignItems(center);
          @include flexDirection(row);
          color: $color-primary;
          gap: 10px;

          .image {
            padding: 5px;
            width: 50px;
            margin-right: 5px;
          }

          .title {
            color: $color-blue-400;
            cursor: pointer;
            font-size: 22px;
            line-height: 100%;
            margin: 10px 0;
            text-decoration: none;
            width: 70%;
          }
        }
      }

      .list {
        max-height: 250px;

        .list-item {
          @extend %flex;
          @include flexDirection(column);
          @include borderRadius(5px 5px 0 0);
          padding: 0;
          min-width: 0;

          .item-top {
            @extend %flex;
            @include flexAlignItems(center);
            @include flexJustifyContent(space-between);
            height: 70px;
            padding: 10px;
            width: 100%;

            .item-text {
              color: $color-primary;
              .MuiTypography-body1 {
                font-weight: bold;
              }
              p {
                color: $color-primary;
                .pharmacy-venc {
                  @extend %flex;
                  @include flexDirection(column);
                  .pharmacy {
                    font-weight: bold;
                  }
                }
              }
            }
            .COTIZADO {
              @include borderRadius(5px);
              height: 24px;
              background-color: $color-yellow-100;

              span {
                color: $color-white-100;
                font-size: 12px;
                line-height: 120%;
                padding: 0 8px;
              }
            }
            .ENVIADO {
              @include borderRadius(5px);
              height: 24px;
              background-color: $color-blue-300;

              span {
                color: $color-white-100;
                font-size: 12px;
                line-height: 120%;
                padding: 0 8px;
              }
            }
          }

          .item-bottom {
            @include borderRadius(0 0 5px 5px);
            //background-color: $color-grey-200;
            height: 30px;
            width: 100%;
            border-radius: 5px 5px 5px 0;

            span {
              @extend %flexCenter;
              color: $color-blue-400;
              font-size: 15px;
              height: 30px;
              line-height: 120%;
              font-weight: bold;
              justify-content: right;
              margin-right: 15px;
              border-top: solid 2px $color-grey-100;
            }
          }
        }
      }
    }
  }
}

.selected-professional {
  @extend %grid;
  grid-gap: 24px;
  margin-top: 25px;

  &--loading {
    @extend %flexCenter;
    min-width: 350px;
    min-height: 350px;
  }

  .data {
    .top {
      padding: 0;

      .text {
        span {
          font-size: 14px;
          font-weight: bold;
        }

        p {
          color: $color-black-100;
          max-width: 300px;
        }

        &--left {
          span {
            color: $color-primary;
          }
        }
        &--right {
          span {
            color: $color-secondary;
          }
        }
      }
    }
    .bottom {
      padding: 0;

      &__title {
        color: $color-secondary;
        font-size: 14px;
        margin: 20px 0 0;
        text-align: left;
      }

      &__text {
        margin: 5px 0;
      }
    }
  }

  .recent-calendar {
    @extend %grid;
    @include gridTemplateRows(repeat(2, auto));

    &__head,
    &__body {
      @extend %grid;
      @include gridTemplateColumns(repeat(5, 1fr));
      grid-gap: 16px;
    }

    .head__date {
      @extend %flexCenter;
      @include flexDirection(column);
      color: $color-primary;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 10px;
    }

    &__body {
      max-height: 350px;
      overflow-y: auto;
      overflow-x: hidden;

      &__column {
        background-color: red;
      }

      .body__schedule {
        @extend %flex;
        @include flexDirection(column);
        @include flexAlignItems(center);
        margin: 10px 0;

        .schedule__chip {
          @include borderRadius(5px);
          background-color: $color-blue-250;
          height: 24px;
          margin: 5px 0;
          cursor: pointer;

          span {
            color: $color-primary;
            font-size: 14px;
            line-height: 120%;
            padding: 0 8px;
          }

          &--unavailable {
            background-color: $color-white-100;

            span {
              color: $color-grey-200;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}

.previous-recommendation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  img{
   border-radius: 20px;
   max-width: 30rem;
   min-width: 30rem;
  }
  .button-accept-recommendation {
    border-radius: 20px;
    box-shadow: none;
    background-color: #002c54;
    color: white;
    width: 10rem;
  }
}

@media screen and (min-width: $breakpoint-medium) {
  .patient {
    @include gridTemplateColumns(1fr);

    .filter-result {
      @include gridTemplateColumns(repeat(2, 1fr));
      @include gridTemplateRows(auto 1fr);

      .header {
        @include gridColumn(1, 2);
        @include gridRow(1, 2);
      }

      .filter {
        @include gridColumn(1, 2);
        @include gridRow(2, 3);
        padding: 0 10px;
      }

      // This class moves the specialties autocomplete to Result column at Patient Dashboard
      // And when appears affects to .result class
      .specialties-to-result-column {
        @include gridColumn(2, 3);
        @include gridRow(1, 2);
        max-width: initial;
        height: 90px;

        & + .result {
          @include gridRow(2, 3);
        }

        .autocomplete {
          padding: 10px;
        }
      }

      .result {
        @include gridColumn(2, 3);
        @include gridRow(1, 3);
        max-height: 100%;
        padding: 0 10px;

        .list {
          position: relative;

          ul {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }

  .selected-professional {
    @include gridTemplateColumns(repeat(2, 1fr));
    margin-top: 25px;
  }
}

@media screen and (min-width: $breakpoint-large) {
  .patient {
    @include gridTemplateColumns(2fr 1fr);
    height: 100%;

    .list {
      height: 100%;
      max-height: 100% !important;
      position: relative;

      ul {
        padding: 10px;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .specialties-to-result-column {
      height: 90px;
    }

    .filter-result {
      grid-gap: 16px !important;

      .filter {
        @include gridTemplateRows(auto 1fr !important);
        grid-gap: 16px !important;
      }
    }

    .data {
      @include gridTemplateRows(repeat(2, 50%));
      grid-gap: 0 !important;
      padding: 0;

      .turn,
      .order {
        padding: 16px 24px;
      }
    }
  }
}
